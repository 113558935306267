import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { OnboardingContainer } from 'components/pages/onboarding/shared';
import { OTPPage } from 'components/pages/userIdentityVerification/OtpPage';
import { PreOtpPage } from 'components/pages/userIdentityVerification/PreOtpPage';
import { SessionTimeout } from 'components/pages/userIdentityVerification/SessionTimedOut';
import {
  sendPSLFOTPNotification,
  submitPSLFOTPVerificationCode,
} from 'redux/actions/pslf.actions';
import { clearServerError } from 'redux/actions/ui.actions';
import {
  sendOTPNotificationCodeLabel,
  submitPSLFOTPCodeLabel,
} from 'redux/middleware/verifyPSLFOtp.middleware';
import { getErrorCode, getErrorMessage } from 'redux/selectors/ui.selectors';
import { decodeJwt, isTokenExpired } from 'utils/jwt';

/**
 * OTPPage component
 * @param {object} props
 * @param {string} props.sendOTPCodeEmailError
 * @param {string} props.submitOTPCodeError
 * @returns {JSX.Element}
 */
const PSLFOTPVerification = ({
  submitOTPCodeError,
  sendOTPNotificationErrorCode,
}) => {
  const [hasCodeBeenRequested, setHasCodeBeenRequested] = useState(false);

  const { search } = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(search);
  const token = queryParams.get('token');
  const decodedToken = decodeJwt(token);

  /**
   * Submits user OTP verification
   * @returns {void}
   */
  const submitVerificationCode = otp => {
    dispatch(submitPSLFOTPVerificationCode(otp, token));
    dispatch(clearServerError(submitPSLFOTPCodeLabel));
  };

  /**
   * Sends OTP notification to user
   * @returns {void}
   */
  const sendOtpNotification = () => {
    dispatch(sendPSLFOTPNotification(token, { meta: {} }));
  };

  if (isTokenExpired(decodedToken?.exp)) {
    return <SessionTimeout />;
  }

  return (
    <OnboardingContainer style={{ maxWidth: 550, gap: 24 }}>
      {!hasCodeBeenRequested ? (
        <PreOtpPage
          pageTitle={`Welcome ${decodedToken?.employerFirstName}, verify your email to approve PSLF forms`}
          sendOTPNotificationErrorCode={sendOTPNotificationErrorCode}
          email={decodedToken?.hrEmail}
          onSendCode={() => {
            dispatch(
              sendPSLFOTPNotification(token, {
                meta: {
                  onSuccess: () => {
                    setHasCodeBeenRequested(true);
                  },
                },
              }),
            );
          }}
        />
      ) : (
        <OTPPage
          pageTitle={`Welcome ${decodedToken?.employerFirstName}, verify your email to approve PSLF forms`}
          email={decodedToken?.hrEmail}
          error={submitOTPCodeError}
          onSubmitCode={submitVerificationCode}
          onSendCode={sendOtpNotification}
        />
      )}
    </OnboardingContainer>
  );
};

const mapStateToProps = state => ({
  submitOTPCodeError: getErrorMessage(state, submitPSLFOTPCodeLabel),
  sendOTPNotificationErrorCode: getErrorCode(
    state,
    sendOTPNotificationCodeLabel,
  ),
});

export default connect(mapStateToProps)(PSLFOTPVerification);
