import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getOidcProvider } from 'redux/actions/auth.actions';

const OAuthIDPInitiated = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const search = new URLSearchParams(location.search);
  const iss = search.get('iss');

  useEffect(() => {
    dispatch(getOidcProvider(iss));
  }, [dispatch, iss]);

  return <div>Authenticating...</div>;
};

export default OAuthIDPInitiated;
