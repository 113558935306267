import React from 'react';
import styled from 'styled-components';

import { FlexColumn, COLORS } from '@summer/ui-components';

import LoadingAnimation from 'components/common/LoadingAnimation';

const LoadingPage = () => {
  return (
    <Container>
      <ContentContainer>
        <LoadingAnimation header="" waveSpeed={0.5} />
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${COLORS.white};
  padding: 72px 40px;
`;

const ContentContainer = styled(FlexColumn)`
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
`;

export default LoadingPage;
