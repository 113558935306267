/**
 * Decodes the JWT token.
 * @param {string} token
 * @returns {Object}
 */
export const decodeJwt = token => {
  if (!token) {
    return null;
  }

  const [, tokenPayload] = token.split('.');
  const decoded = atob(tokenPayload);
  return JSON.parse(decoded);
};

export const isTokenExpired = exp => {
  if (!exp) {
    return false;
  }

  if (typeof exp !== 'number') {
    return true;
  }

  const today = new Date();
  const expirationDate = new Date(exp * 1000);

  return today > expirationDate;
};
