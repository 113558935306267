import React from 'react';

/**
 * Adds <wbr> tag to long email address
 * @param {string} email
 * @returns {JSX.Element}
 */
export const addWbrToLongEmail = (email = '') => {
  const LONG_EMAIL_LENGTH = 40;
  if (email.length < LONG_EMAIL_LENGTH) {
    return email;
  }

  const [emailFirstHalf, emailSecondHalf] = email.split('@');
  return (
    <>
      {emailFirstHalf}
      <wbr />@{emailSecondHalf}
    </>
  );
};
