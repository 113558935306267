import { find, toLower } from 'lodash';
import React from 'react';

import { Text } from '@summer/ui-components';

import { initFeatureFlagsFromObject } from 'services/featureFlags';
import {
  AftError,
  getAftInitialValues,
  getAftPartnerIdentifierData,
  getAftUrlPartnerData,
  getNewAftPartnerIdentifierData,
  getAftConventionPartnerIdentifierData,
  aftConventionValidation,
} from 'services/partner/aftOnboard';
import {
  getFidelityUrlPartnerData,
  getFidelityIdentifierData,
} from 'services/partner/fidelityOnboard';
import {
  getMattressFirmPartnerIdentifierData,
  mattressFirmValidation,
} from 'services/partner/mattressFirm';
import {
  getNerdWalletUrlPartnerData,
  getNerdWalletIdentifierData,
} from 'services/partner/nerdWalletOnboard';

const ENV = import.meta.env.REACT_APP_NODE_ENV || import.meta.env.NODE_ENV;

const getQuery = () => {
  const search = window?.location?.search;
  return URLSearchParams ? new URLSearchParams(search) : undefined;
};

const getLogoFromUrl = () => {
  const queryParams = getQuery();

  return queryParams.get('logoSrc');
};

const defaultInitialValuesWithAutoFill = () => {
  const queryParams = getQuery();

  return {
    firstName: queryParams?.get('firstName') ?? '',
    lastName: queryParams?.get('lastName') ?? '',
    email: queryParams?.get('email') ?? '',
    password: '',
    partnerCode: '',
    termsOfService: false,
  };
};

export const HeaderText = {
  defaultHeaderText: `We've partnered to help you save.`,
  findYourPath: 'Find your path out of student debt.',
  freedomFromDebt: 'Making freedom from student debt a reality.',
  retirementMatch: 'Match student loan payments toward your retirement',
  yourWay: 'Your student loan savings benefit, your way.', // TODO: only used by mattress firm,
  convenientAndMeaningful:
    'Convenient and meaningful education benefits at your fingertips',
};

export const BenefitsHeaderText = {
  defaultBenefitsHeaderText: 'Account Benefits:',
  withSummer: 'With Summer you can:',
  withYourAccount: 'With your account, you’ll be able to:',
  premiumBenefits: 'Premium Partner Account benefits:',
};

export const Testimonial = {
  jennifer: 'jennifer',
  shante: 'shante',
  corry: 'corry',
  jackalyne: 'jackalyne',
};

export const OutroParagraph = {
  aft: 'AFT members have saved millions through Summer!',
  fidelity:
    'Create your account for free with the option to upgrade for the full digital PSLF experience, unlimited support, and a money-back guarantee!',
  createYourFreeAccount: 'Create your free account today.',
  paywallPartner:
    'Create your free account today and start taking action, with the option to upgrade for the full digital experience, unlimited support, and a money-back guarantee!',
};

const sharedPartnerConfigs = {
  aft: {
    title: 'AFT',
    hasLogo: true,
    logoSrc: '/images/partners/aft-logo.png',
    logoWidth: 160,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: true,
    useOauth: true,
    urlPartnerData() {
      return getAftUrlPartnerData();
    },
    getPartnerIdentifierData() {
      return getAftPartnerIdentifierData(this.urlPartnerData());
    },
    get introParagraph() {
      return `AFT members now have access to Summer Premium, the student loan assistance platform to lower your payments and apply for forgiveness.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.jennifer,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.premiumBenefits,
    // Partner-specific configuration
    errorPage: <AftError />,
    waitlistLink:
      'https://docs.google.com/forms/d/e/1FAIpQLScbfa6KXDfmKGHXd-BSHD2ldPykB1S6cDpZaS4q9ycQVucDLQ/viewform?usp=sf_link',
    getInitialValues() {
      return getAftInitialValues(this.urlPartnerData());
    },
  },
  aftbenefit: {
    title: 'AFT Benefit',
    hasLogo: true,
    logoSrc: '/images/partners/aft-logo.png',
    logoWidth: 160,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: true,
    useOauth: true,
    urlPartnerData() {
      return {
        isValidUrl: true,
      };
    },
    getPartnerIdentifierData(values) {
      return getNewAftPartnerIdentifierData(values);
    },
    get introParagraph() {
      return `All AFT members have access to Summer’s Premium student debt tool and resources.`;
    },
    outroParagraph: OutroParagraph.aft,
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.premiumBenefits,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Apply for forgiveness',
      'Enroll in IDR',
      'Lower your payments',
      'Track your loans',
    ],
    // Partner-specific configuration
    usePartnerGuid: true,
    partnerGuidHelpText:
      'Use the unique code shared with you via email from AFT.',
  },
  aftconvention: {
    title: 'AFT Convention',
    hasLogo: true,
    logoSrc: '/images/partners/aft-logo.png',
    logoWidth: 160,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: true,
    useOauth: true,
    urlPartnerData() {
      return {
        isValidUrl: true,
      };
    },
    getPartnerIdentifierData(values) {
      return getAftConventionPartnerIdentifierData(values);
    },
    get introParagraph() {
      return `All AFT members have access to Summer’s Premium student debt tool and resources.`;
    },
    outroParagraph: OutroParagraph.aft,
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.premiumBenefits,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Apply for forgiveness',
      'Enroll in IDR',
      'Lower your payments',
      'Track your loans',
    ],
    // Partner-specific configuration
    signupValidation: aftConventionValidation,
  },
  ada: {
    title: 'American Diabetes Association',
    hasLogo: true,
    logoSrc: '/images/partners/ada-logo.png',
    logoWidth: 160,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `All American Diabetes Association employees and their family members now have access to Summer’s student debt resources.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.jennifer,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
  },
  cff: {
    title: 'Cystic Fibrosis Foundation',
    hasLogo: true,
    logoSrc: '/images/partners/cff-logo.png',
    logoWidth: 160,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `All Cystic Fibrosis Foundation employees and their family members now have access to Summer’s student debt resources.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.jennifer,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
  },
  alleninstitute: {
    title: `Allen Institute`,
    hasLogo: true,
    logoSrc: '/images/partners/allen-institute-logo.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `Allen Institute employees now have access to Summer’s student debt resources.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in forgiveness programs',
      'Get advice from student loan experts',
      'Compare repayment plans',
    ],
  },
  cityofalexandria: {
    title: `City of Alexandria`,
    hasLogo: true,
    logoSrc: '/images/partners/city-of-alexandria-logo.png',
    logoWidth: 80,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `All City of Alexandria employees now have access to Summer's Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
    // Partner-specific configuration
    oauthProviders: ['cityOfAlexandria'],
  },
  asurion: {
    title: `Asurion`,
    hasLogo: true,
    logoSrc: '/images/partners/asurion-logo.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Asurion employees have access to Summer’s premium student loan and education services.`;
    },
    emailHelpMessage: `Use your Asurion, NewCorp or UBreakIFix email address to receive premium access`,
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    headerText: HeaderText.convenientAndMeaningful,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in Tuition Reimbursement',
      'Track your loans',
      'Compare repayment plans',
      'Enroll in forgiveness programs',
      'Access guides and resources',
      'Get advice from student loan experts',
    ],
  },
  mfrm: {
    title: 'Mattress Firm and Summer',
    hasLogo: true,
    logoSrc: '/images/partners/mattress-firm-logo.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: true,
    useOauth: false,
    urlPartnerData() {
      return {
        isValidUrl: true, // hack!
      };
    },
    getPartnerIdentifierData(values) {
      return getMattressFirmPartnerIdentifierData(values);
    },
    get introParagraph() {
      return `All Mattress Firm employees and your families now have access to Summer's Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    headerText: HeaderText.yourWay,
    benefitsHeaderText: BenefitsHeaderText.premiumBenefits,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
    // Partner-specific configuration
    signupValidation: mattressFirmValidation,
    useCustomCode: true,
    customCodeName: 'mattressFirmId',
    customCodeLabel: 'Active Directory ID',
    customCodeHelpText: 'Please enter your Active Directory ID',
  },
  apiDemo: {
    title: `API Demo Partner`,
    hasLogo: true,
    logoSrc: getLogoFromUrl,
    logoWidth: 144,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `API users now have access to Summer’s student loan tools and resources.`;
    },
    emailHelpMessage: ``,
    hideLogoCopy: false,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.withSummer,
    infoItems: [
      'Lower your payments',
      'Apply for loan forgiveness',
      'Access expert guidance and support',
    ],
    // Partner-specific configuration
    getInitialValues() {
      return defaultInitialValuesWithAutoFill();
    },
    featureFlags: {
      showPlaidInIdr: 'true',
    },
  },
  general: {
    title: 'General Paywall',
    hasLogo: false,
    hideLogoCopy: false,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: true,
    headerText: HeaderText.findYourPath,
    introParagraph:
      'Get started with Summer, the digital solution designed to help you tackle your student debt once and for all.',
    benefitsHeaderText: BenefitsHeaderText.withSummer,
    outroParagraph: OutroParagraph.paywallPartner,
    infoItems: [
      'Compare repayment and forgiveness options',
      'Track the status of your loans',
      'Check your eligibility for repayment plans in minutes',
      'Navigate the requirements for forgiveness',
      'Easily sign and submit necessary documents online',
      'Access guides and resources',
      'Connect with student loan experts',
    ],
    testimonial: Testimonial.corry,
  },
  fallschurchva: {
    title: 'City of Falls Church',
    hasLogo: true,
    logoSrc: '/images/partners/fallschurchva-logo.png',
    logoWidth: 80,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `All Falls Church employees now have access to Summer\u2019s Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.shante,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  fidelity: {
    title: 'Fidelity ',
    hasLogo: true,
    logoSrc: '/images/partners/fidelity-logo.png',
    logoWidth: 140,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: true,
    useOauth: true,
    urlPartnerData() {
      return getFidelityUrlPartnerData();
    },
    getPartnerIdentifierData() {
      return getFidelityIdentifierData(this.urlPartnerData());
    },
    introParagraph() {
      return (
        <>
          Welcome Fidelity participants!{' '}
          <Text large paragraph as="span">
            Get started with Summer, the digital PSLF solution designed to help
            you tackle your student debt and get forgiveness.
          </Text>
        </>
      );
    },
    outroParagraph: OutroParagraph.fidelity,
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.corry,
    headerText: `Find your path out of student debt.`,
    benefitsHeaderText: BenefitsHeaderText.withSummer,
    infoItems: [
      'Check your eligibility in minutes',
      'Navigate the requirements for forgiveness',
      'Compare your repayment and forgiveness options',
      'Easily sign and submit necessary documents online',
    ],
    // Partner-specific configuration
    customQuote:
      '\u201CSummer saved me nearly $20,000 this year! I couldn\u0027t have done this alone.\u201D',
    // hiddenToolsList correlates to tool pathnames.
    hiddenToolsList: ['/overpayments'],
  },
  cityofakron: {
    title: 'City of Akron',
    hasLogo: true,
    logoSrc: '/images/partners/cityofakron-logo.jpg',
    logoWidth: 94,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `All City of Akron employees now have access to Summer\u2019s Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jennifer,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  uhpa: {
    title: 'University of Hawaii Professional Assembly',
    hasLogo: true,
    logoSrc: '/images/partners/uhpa-logo.png',
    logoWidth: 360,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `AFT and UHPA have partnered with Summer to give you access to premium student debt assistance.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.shante,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Apply for Public Service Loan Forgiveness',
      'Access expert support',
      'Lower your payments',
      'Track your loans',
      'Compare savings plans',
    ],
  },
  winter: {
    title: 'Winter',
    hasLogo: true,
    logoSrc: '/images/partners/winter-logo.png',
    logoWidth: 94,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return 'All Winter employees now have access to Student Loan Contribution';
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.corry,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Access expert support',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  techsmith: {
    title: 'TechSmith',
    hasLogo: true,
    logoSrc: '/images/partners/techsmith-logo.png',
    logoWidth: 195,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return 'TechSmith is helping you pay down your student loan debt faster!';
    },
    emailHelpMessage: 'Use your TechSmith email address to get verified access',
    hideLogoCopy: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Sync up your student loans',
      'Enroll in your employer\u2019s student loan contribution benefit',
      'Access free guides and resources',
    ],
  },
  cityofauburn: {
    title: 'City of Auburn, Washington',
    hasLogo: true,
    logoSrc: '/images/partners/cityofauburn-logo.png',
    logoWidth: 141,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return 'All City of Auburn employees and family now have access to Summer’s Premium student debt tools and resources.';
    },
    hideLogoCopy: true,
    testimonial: Testimonial.corry,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Apply for forgiveness',
      'Enroll in a new repayment plan',
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Track your loans',
    ],
  },
  nerdwallet: {
    title: 'NerdWallet',
    hasLogo: true,
    hideLogoCopy: false,
    logoSrc: '/images/partners/nerdwallet-logo.png',
    logoWidth: 300,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: true,
    useOauth: true,
    headerText: HeaderText.findYourPath,
    introParagraph:
      'Get started with Summer, the digital solution designed to help you tackle your student debt once and for all.',
    benefitsHeaderText: BenefitsHeaderText.withSummer,
    outroParagraph: OutroParagraph.paywallPartner,
    urlPartnerData() {
      return getNerdWalletUrlPartnerData();
    },
    getPartnerIdentifierData() {
      return getNerdWalletIdentifierData(this.urlPartnerData());
    },
    infoItems: [
      'Compare repayment and forgiveness options',
      'Track the status of your loans',
      'Check your eligibility for repayment plans in minutes',
      'Navigate the requirements for forgiveness',
      'Easily sign and submit necessary documents online',
      'Access guides and resources',
      'Connect with student loan experts',
    ],
    testimonial: Testimonial.corry,
  },
  thehome: {
    title: 'The Home for Little Wanderers',
    hasLogo: true,
    logoSrc: '/images/partners/little-wanderers.png',
    logoWidth: 200,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    introParagraph:
      'The Home for Little Wanderers is helping you pay down your student loan debt faster!',
    emailHelpMessage: '',
    hideLogoCopy: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Sync up your student loans',
      'Enroll in your employer\u2019s student loan contribution benefit',
      'Access free guides and resources',
    ],
  },
  strongsville: {
    title: 'Strongsville',
    hasLogo: true,
    logoSrc: '/images/partners/strongsville-logo.png',
    logoWidth: 100,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: false,
    introParagraph:
      'All Strongsville employees and family now have access to Summer’s Premium student debt tool and resources.',
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jennifer,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  vaxart: {
    title: 'Vaxart',
    hasLogo: true,
    logoSrc: '/images/partners/vaxart-logo.png',
    logoWidth: 130,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `All Vaxart employees and their family members have access to Summer\u2019s premium student loan support.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.corry,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Plan for the future',
      'Track your loans',
    ],
  },
  current: {
    title: 'Current',
    hasLogo: true,
    logoSrc: '/images/partners/current-logo.png',
    logoWidth: 140,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `All Current employees and family now have access to Summer\u2019s Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.corry,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  vita: {
    title: 'Vita',
    hasLogo: true,
    logoSrc: '/images/partners/vita-logo.png',
    logoWidth: 140,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `Vita and Summer have partnered to give you access to Summer\u2019s Premium student debt tool and resources.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.corry,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Access expert support',
      'Compare savings plans',
      'Lower your payments',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  hurleymc: {
    title: 'Hurley Medical Center',
    hasLogo: true,
    logoSrc: '/images/partners/hurleymc-logo.png',
    logoWidth: 140,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `Hurley Medical Center employees have access to convenient and easy-to-use student loan support from Summer.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Speak to a student loan expert',
      'Compare personalized savings plans',
      'Digitally enroll in PSLF and IDR',
      'Plan for future college costs',
      'Sync and track loans in one place',
    ],
  },
  lsswis: {
    title: 'Lutheran Social Services of Wisconsin & Upper Michigan',
    hasLogo: true,
    logoSrc: '/images/partners/lsswis-logo.jpg',
    logoWidth: 100,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `LSS WIS employees have access to convenient and easy-to-use student loan support from Summer.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    headerText: HeaderText.freedomFromDebt,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Speak to a student loan expert',
      'Compare personalized savings plans',
      'Digitally enroll in PSLF and IDR',
      'Plan for future college costs',
      'Sync and track loans in one place',
    ],
  },
  housingworks: {
    title: 'Housing Works',
    hasLogo: true,
    logoSrc: '/images/partners/housingworks-logo.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `Housing Works employees and family have access to convenient and easy-to-use student loan support from Summer.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    headerText: HeaderText.freedomFromDebt,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Speak to a student loan expert',
      'Compare personalized savings plans',
      'Digitally enroll in PSLF and IDR',
      'Plan for future college costs',
      'Sync and track loans in one place',
    ],
  },
  petvet: {
    title: 'PetVet',
    hasLogo: true,
    logoSrc: '/images/partners/petvet-logo.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `PetVet employees and family have access to convenient and easy-to-use student loan support from Summer.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.corry,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Speak to a student loan expert',
      'Compare personalized savings plans',
      'Digitally enroll in federal programs',
      'Plan for future college costs',
      'Sync and track loans in one place',
    ],
  },
  learnbehavioral: {
    title: 'LEARN Behavioral',
    hasLogo: true,
    logoSrc: '/images/partners/learn-behavioral-logo.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `LEARN employees and family members have access to convenient and easy-to-use student loan support from Summer.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Speak to a student loan expert',
      'Compare personalized savings plans',
      'Digitally enroll in federal programs',
      'Plan for future college costs',
      'Sync and track loans in one place',
    ],
  },
  transformationsnetwork: {
    title: 'Transformations Care Network',
    hasLogo: true,
    logoSrc: '/images/partners/transformations-care-network.png',
    logoWidth: 300,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan contribution benefit ',
      'Receive custom repayment recommendations',
      'Digitally apply for Income-Driven Repayment',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  napaanesthesia: {
    title: 'NAPA Anesthesia',
    hasLogo: true,
    logoSrc: '/images/partners/napaanesthesia.png',
    logoWidth: 200,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `NAPA Anesthesia is helping you pay down your student loan debt faster!`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Sync up your student loans',
      'Enroll in your employer’s student loan contribution benefit',
      'Access free guides and resources',
    ],
  },
  fairlife: {
    title: 'Fairlife',
    hasLogo: true,
    logoSrc: '/images/partners/fairlife.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    outroParagraph: OutroParagraph.createYourFreeAccount,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Digitally apply for Income-Driven Repayment',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  elementcare: {
    title: 'Element Care',
    hasLogo: true,
    logoSrc: '/images/partners/element-care.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Digitally apply for Public Service Loan Forgiveness',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  nortonhealthcare: {
    title: 'Norton Healthcare',
    hasLogo: true,
    logoSrc: '/images/partners/nortonhealthcare-logo.png',
    logoWidth: 250,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: 'Find your path out of student debt.',
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Save for future college costs',
      'Access free guides and resources',
    ],
  },
  quorumhealth: {
    title: 'Quorum Health',
    hasLogo: true,
    logoSrc: '/images/partners/quorumhealth-logo.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  cipe: {
    title: 'Center for International Private Enterprise (CIPE)',
    hasLogo: true,
    logoSrc: '/images/partners/cipe.png',
    logoWidth: 120,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Save for future college costs',
      'Get in touch with student loan experts',
      'Digitally apply for Public Service Loan Forgiveness',
    ],
  },
  enel: {
    title: 'Enel North America, Inc.',
    hasLogo: true,
    logoSrc: '/images/partners/enel.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  trustage: {
    title: 'TruStage',
    hasLogo: true,
    logoSrc: '/images/partners/trustage-logo.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan retirement match',
      'Receive custom repayment recommendations',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  family: {
    title: 'Family',
    hasLogo: false,
    isPremium: false,
    usePartnerCode: true,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  abs: {
    title: 'American Bureau of Shipping',
    hasLogo: true,
    logoSrc: '/images/partners/abs-logo.png',
    logoWidth: 250,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Receive custom repayment recommendations',
      'Digitally apply for Income-Driven Repayment',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  prnpt: {
    title: 'Physical Rehabilitation Network',
    hasLogo: true,
    logoSrc: '/images/partners/prn-logo.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Save for future college costs',
      'Get in touch with student loan experts',
    ],
  },
  childrenshome: {
    title: "Children's Home",
    hasLogo: true,
    logoSrc: '/images/partners/childrenshome-logo.png',
    logoWidth: 200,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: false,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Get in touch with student loan experts',
      'Digitally apply for Public Service Loan Forgiveness',
    ],
  },
  chegg: {
    title: 'Chegg',
    hasLogo: true,
    logoSrc: '/images/partners/chegg-logo.png',
    logoWidth: 170,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: true,
    hideAccountForm: true,
    get introParagraph() {
      return `Convenient and easy-to use student loan benefits at your fingertips.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jackalyne,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Enroll in your student loan contribution benefit',
      'Receive custom repayment recommendations',
      'Enroll in your tuition reimbursement benefit',
      'Get in touch with student loan experts',
    ],
    oauthProviders: ['chegg'],
  },
};

const getRecordKeeper = () => {
  const queryParams = getQuery();

  return queryParams.get('recordkeeper');
};

const demoPartnerConfigs = {
  'demo-record-keeper': {
    title: 'Demo Record Keeper',
    hasLogo: false,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    get introParagraph() {
      return `${
        getRecordKeeper() || `[Recordkeeper name]`
      } and your employer are helping you save for retirement by matching the amount of your student loan payments into your retirement plan.`;
    },
    headerText: HeaderText.retirementMatch,
    useOauth: false,
    emailHelpMessage: '',
    hideLogoCopy: false,
    benefitsHeaderText: BenefitsHeaderText.withYourAccount,
    infoItems: [
      'Sync your student loans and confirm that they qualify',
      'Track your payments so they can be matched to your retirement plan',
      'Access free guides and resources',
    ],
    // Partner-specific configuration
    getInitialValues() {
      return defaultInitialValuesWithAutoFill();
    },
    isRecordKeeper: true,
    outroFinePrintParagraph:
      'The amount you receive can\u2019t exceed the maximum match percentage offered by your employer.',
  },
  'adp-demo': {
    title: 'ADP',
    hasLogo: true,
    logoSrc: '/images/partners/adp-logo.png',
    logoWidth: 130,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    get introParagraph() {
      return `ADP and your employer are helping you save for retirement by matching the amount of your student loan payments into your retirement plan.`;
    },
    headerText: HeaderText.retirementMatch,
    useOauth: false,
    emailHelpMessage: '',
    hideLogoCopy: false,
    benefitsHeaderText: BenefitsHeaderText.withYourAccount,
    infoItems: [
      'Sync your student loans and confirm that they qualify',
      'Track your payments so they can be matched to your retirement plan',
      'Access free guides and resources',
    ],
    // Partner-specific configuration
    getInitialValues() {
      return defaultInitialValuesWithAutoFill();
    },
    outroFinePrintParagraph:
      'The amount you receive can\u2019t exceed the maximum match percentage offered by your employer.',
  },
  'assistance-demo': {
    title: 'Assistance Demo',
    hasLogo: false,
    isPremium: true,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `All members now have access to Summer\u2019s Premium tuition assistance tool and resources.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: true,
    testimonial: Testimonial.jennifer,
    headerText: HeaderText.findYourPath,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
    infoItems: [
      'Access expert support',
      'Apply for a forgiveness plan',
      'Track your loans',
    ],
  },
  'referral': {
    title: 'Referral',
    hasLogo: false,
    isPremium: false,
    usePartnerCode: false,
    hasPartnerIdentifierData: false,
    useOauth: true,
    get introParagraph() {
      return `Create an account to access Summer’s student debt resources.`;
    },
    emailHelpMessage: '',
    hideLogoCopy: false,
    testimonial: Testimonial.jennifer,
    headerText: HeaderText.defaultHeaderText,
    benefitsHeaderText: BenefitsHeaderText.defaultBenefitsHeaderText,
  },
};

export const partnerConfig =
  ENV === 'development' ||
  ENV === 'sandbox' ||
  ENV === 'staging' ||
  ENV === 'test'
    ? { ...sharedPartnerConfigs, ...demoPartnerConfigs }
    : { ...sharedPartnerConfigs };

export const initFeatureFlagsForPartners = partner => {
  const currentPartnerConfig = find(partnerConfig, (_, partnerKey) => {
    return toLower(partnerKey) === toLower(partner);
  });
  initFeatureFlagsFromObject(currentPartnerConfig?.featureFlags);
};
