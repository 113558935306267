import { push as redirectTo } from 'connected-react-router';

import {
  NETWORK_SUCCESS,
  SEND_USER_OTP_NOTIFICATION_EMAIL,
  VERIFY_USER_OTP_CODE,
} from 'constants/actionTypes';
import { welcome } from 'constants/onboardingSteps';
import { apiRequest } from 'redux/actions/api.actions';
import {
  persistOnboardingStep,
  setOnboardingStep,
} from 'redux/actions/onboard.actions';
import { hydrateUser } from 'redux/actions/user.actions';
import handleSideEffects from 'redux/handleSideEffects';

export const submitUserOtpVerificationCodeLabel =
  'submitUserOtpVerificationCode';
export const sendUserOtpNotificationLabel = 'sendUserOtpNotification';

const verifyUserOtpMiddleware = handleSideEffects({
  [SEND_USER_OTP_NOTIFICATION_EMAIL]: dispatch => {
    dispatch(
      apiRequest({
        url: '/v2/email/verification',
        method: 'POST',
        fromAction: SEND_USER_OTP_NOTIFICATION_EMAIL,
        label: sendUserOtpNotificationLabel,
      }),
    );
  },

  [VERIFY_USER_OTP_CODE]: (dispatch, { code }) => {
    const endpoint = '/v2/user/verify';
    const method = 'POST';

    dispatch(
      apiRequest({
        url: endpoint,
        method,
        fromAction: VERIFY_USER_OTP_CODE,
        label: submitUserOtpVerificationCodeLabel,
        body: { code },
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { meta }) => {
    if (meta.fromAction === VERIFY_USER_OTP_CODE) {
      dispatch(setOnboardingStep(welcome));
      dispatch(persistOnboardingStep(welcome));
      dispatch(hydrateUser());
      dispatch(redirectTo(welcome));
    }
  },
});

export default verifyUserOtpMiddleware;
