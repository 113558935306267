import { Form, Formik } from 'formik';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';
import { object } from 'yup';

import { Button, COLORS, Text, TextInput } from '@summer/ui-components';

import GlobalError from 'components/shared/GlobalError';
import { login } from 'redux/actions/user.actions';
import { getErrorMessage, isLoading } from 'redux/selectors/ui.selectors';
import { passwordFieldValidation } from 'schemas/user';

export const passwordLoginValidationSchema = object().shape({
  password: passwordFieldValidation('Please enter password'),
});

const PasswordLoginMethod = ({ email, location, isLoading }) => {
  const dispatch = useDispatch();
  const formInitialValues = {
    password: '',
  };

  const passwordLogin = values => {
    const { password } = values;
    const { state } = location || {};

    dispatch(
      login(
        {
          email,
          password,
        },
        state,
      ),
    );
  };

  const renderForm = ({
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    isSubmitting,
    isValid,
  }) => {
    return (
      <Form>
        <Box>
          <TextInput
            label="Password"
            type="password"
            name="password"
            touched={touched.password}
            isSubmitting={isSubmitting}
            error={errors.password}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="current-password"
          />
        </Box>
        <GlobalError label="login" />
        <Button
          disabled={!isValid || isLoading}
          isLoading={isLoading}
          width={360}
          type="submit"
        >
          Login
        </Button>
        <LinkBox>
          <Text>
            <Link to="/forgot" underline style={{ color: COLORS.darkerGrey }}>
              Forgot your password?
            </Link>
          </Text>
        </LinkBox>
      </Form>
    );
  };

  return (
    <Container>
      <Formik
        initialValues={formInitialValues}
        validationSchema={passwordLoginValidationSchema}
        validateOnMount={true}
        onSubmit={passwordLogin}
      >
        {renderForm}
      </Formik>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Box = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const LinkBox = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'login'),
  error: getErrorMessage(state, 'login'),
});

export default connect(mapStateToProps)(PasswordLoginMethod);
