import { push as redirectTo } from 'connected-react-router';
import { format } from 'date-fns';
import { get } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { FormTypes } from '@simplifidev/shared/dist/constants/forms';

import {
  Text,
  Strong,
  Header,
  FlexColumn,
  FlexRow,
  COLORS,
} from '@summer/ui-components';

import HellosignAlert from 'components/common/HellosignAlert';
import SignRequestRow from 'components/common/SignRequestRow';
import Platform, { MOBILE } from 'components/hoc/Platform';
import PrivacyPolicyLink from 'components/shared/PrivacyPolicyLink';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import {
  marketingSiteLink,
  pslfGuideLink,
} from 'constants/externalLinks/marketingSite';
import { TabletSize, MobileSize } from 'constants/styleguide';
import { useHellosign, useAlertHellosignError } from 'hooks/hellosign';
import {
  updateSignatureRequestJwt,
  fetchSignatureRequestJwt,
} from 'redux/actions/signatureRequests.actions';
import {
  getErrorMessage,
  isLoadingWithInit,
} from 'redux/selectors/ui.selectors';
import { hexToRgba } from 'utils/common';
import { decodeJwt, isTokenExpired } from 'utils/jwt';
import { track } from 'utils/track';

import { SessionTimeout } from '../userIdentityVerification/SessionTimedOut';

/**
 * Extracts the employer sign query data from the URL query parameters.
 * @param {URLSearchParams} queryParams
 * @returns {Object}
 * @returns {string} returns.token
 * @returns {string} returns.userId
 * @returns {string} returns.signRequestId
 * @returns {string} returns.employerFirstName
 * @returns {string} returns.employerCompany
 * @returns {string} returns.employerStartDate
 * @returns {string} returns.employerEndDate
 * @returns {boolean} returns.isVerified
 * @returns {string} returns.hrEmail
 * @returns {string} returns.userFirstName
 * @returns {string} returns.userLastName
 * @returns {string} returns.userEmail
 * @returns {string} returns.utm_source
 * @returns {string} returns.utm_campaign
 * @returns {string} returns.iat
 */
const getEmployerSignQueryData = queryParams => {
  const requiredDecodedKeys = [
    'token',
    'userId',
    'signRequestId',
    'isVerified',
    'hrEmail',
    'userFirstName',
    'userLastName',
    'userEmail',
    'employerFirstName',
    'employerCompany',
    'employerStartDate',
    'employerEndDate',
    'utm_source',
    'utm_campaign',
    'iat',
  ];

  try {
    const token = queryParams.get('token');
    const tokenData = decodeJwt(token);

    const formattedData = {
      token,
      ...tokenData,
    };

    const missingKeys = requiredDecodedKeys.filter(
      key => formattedData[key] === undefined,
    );
    if (missingKeys.length > 0) {
      throw new Error(`Missing keys: ${missingKeys.join(', ')}`);
    }

    return formattedData;
  } catch (error) {
    return null;
  }
};

/**
 * EmployerPageV2 is the page that employers land on when they receive an email
 * from a borrower requesting their signature on a PSLF form.
 * @param {Object} props
 * @param {Function} props.dispatch
 * @param {string} props.platformType
 * @param {number} props.windowWidth
 * @param {string} props.employerSignURL
 * @param {Object} props.location
 * @param {boolean} props.isLoading
 * @param {string} props.serverError
 * @param {string} props.otpVerificationError
 * @param {string} props.emailResendError
 * @returns {React.ReactElement}
 */
const EmployerPageV2 = ({
  platformType,
  windowWidth,
  employerSignURL,
  isLoading,
  serverError,
}) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const queryParams = new URLSearchParams(search);

  const headerRef = useRef(null);
  const [backgroundHeight, setBackgroundHeight] = useState();

  const [handleButtonClick] = useHellosign(
    () =>
      dispatch(
        fetchSignatureRequestJwt({
          token: employerVerificationData?.token,
          signRequestId: employerVerificationData?.signRequestId,
        }),
      ),
    {
      onOpen: () => {
        track('Employer Open Document', {
          borrowerEmail: employerVerificationData?.userEmail,
          signRequestId: employerVerificationData?.signRequestId,
        });
      },
      onSign: data => {
        track('Employer Sign', {
          borrowerEmail: employerVerificationData?.userEmail,
          signRequestId: employerVerificationData?.signRequestId,
        });
        dispatch(
          updateSignatureRequestJwt(
            employerVerificationData?.token,
            data.signatureId,
            true,
          ),
        );
      },
    },
  );
  const alertHellosignError = useAlertHellosignError(serverError);
  const employerVerificationData = getEmployerSignQueryData(queryParams);

  const {
    isVerified,
    exp: expirationDate,
    userFirstName,
    userLastName,
    employerCompany,
    employerStartDate,
    employerEndDate,
  } = employerVerificationData ?? {};

  useEffect(() => {
    setBackgroundHeight(headerRef.current?.offsetTop ?? 0);
  }, [windowWidth, isVerified]);

  useEffect(() => {
    dispatch(
      fetchSignatureRequestJwt({
        token: employerVerificationData?.token,
        signRequestId: employerVerificationData?.signRequestId,
      }),
    );
  }, [
    dispatch,
    employerVerificationData?.token,
    employerVerificationData?.signRequestId,
  ]);

  useEffect(() => {
    if (!isVerified) {
      dispatch(
        redirectTo(
          `/employer-signature-v2/verification?token=${employerVerificationData?.token}`,
        ),
      );
    }
  }, [isVerified, dispatch, employerVerificationData?.token]);

  const isExpired = isTokenExpired(expirationDate);

  if (!employerVerificationData || isExpired) {
    return <SessionTimeout />;
  }

  const isMobile = platformType === MOBILE;

  const signRequest = {
    formType: FormTypes.pslf,
    signers: [employerSignURL],
  };

  const endDate = employerEndDate
    ? format(new Date(employerEndDate), 'MMM yyyy')
    : 'Present';

  const signatureChanged = serverError === 'Not Found';

  let signRequestSubtitle = isMobile
    ? employerCompany
    : `${employerCompany}, ${format(
        new Date(employerStartDate),
        'MMM yyyy',
      )} — ${endDate}`;
  let signRequestTitle = `${userFirstName} ${userLastName}`;
  if (signatureChanged) {
    signRequest.signers = [];
    signRequestTitle = `This link has expired because ${employerVerificationData?.userFirstName} has made changes`;
    signRequestSubtitle =
      'Check your email for a more recent email from Summer';
  }

  const mailHref = `mailto:${supportAddress}?cc=${employerVerificationData?.userEmail}`;

  return (
    <Container>
      <Background backgroundHeight={backgroundHeight} />
      <TopContainer>
        <Header>
          Hi, {employerVerificationData?.employerFirstName}!{' '}
          {employerVerificationData?.userFirstName} is requesting your help.
        </Header>
        <VerifyEmploymentContainer>
          <Text paragraph>
            Please verify their employment so they can qualify for Public
            Service Loan Forgiveness and save on their student loan debt.
          </Text>
        </VerifyEmploymentContainer>
        {alertHellosignError && (
          <HellosignAlertContainer>
            <HellosignAlert />
          </HellosignAlertContainer>
        )}
        <div ref={headerRef} />
        <SignRequestRow
          signRequest={signRequest}
          onClick={handleButtonClick}
          signRequestTitle={signRequestTitle}
          signRequestSubtitle={signRequestSubtitle}
          isLoading={isLoading || alertHellosignError}
          isExpired={isExpired}
        />
        <SomethingMissingContainer>
          <Text paragraph>
            <Strong>Something missing or incorrect? </Strong>
            Email Summer at <Link href={mailHref}>{supportAddress}</Link> and
            copy {employerVerificationData?.userFirstName} at{' '}
            {employerVerificationData?.userEmail}.
          </Text>
        </SomethingMissingContainer>
      </TopContainer>
      <Divider />
      <BottomContainer>
        <PslfHeader>
          <Header as={isMobile ? 'h2' : 'h1'}>
            Public Service Loan Forgiveness with Summer
          </Header>
        </PslfHeader>
        <PslfInfo>
          <ListHeaderContainer>
            <Header as="h5">What is Summer?</Header>
          </ListHeaderContainer>
          <Text paragraph>
            <Link inheritColor underline href={marketingSiteLink}>
              Summer
            </Link>{' '}
            is a social enterprise that helps student loan borrowers enroll in
            free federal savings and forgiveness programs, including Public
            Service Loan Forgiveness. As part of our service, we provide a free
            tool to help non-profit and government employers with the
            certification process for Public Service Loan Forgiveness.
          </Text>
          <ListHeaderContainer>
            <Header as="h5">What is Public Service Loan Forgiveness?</Header>
          </ListHeaderContainer>
          <Text paragraph>
            Public Service Loan Forgiveness is a federal program that allows
            borrowers working in the public and non-profit sectors to have their
            remaining debt forgiven after 10 years of qualifying payments. You
            can read more about it{' '}
            <Link inheritColor underline href={pslfGuideLink}>
              here
            </Link>
            .
          </Text>
          <PrivacyPolicyPSLFEmployerPage />
        </PslfInfo>
      </BottomContainer>
    </Container>
  );
};

const PrivacyPolicyPSLFEmployerPage = styled(PrivacyPolicyLink)`
  margin-top: 45px;
  justify-content: flex-start;
`;

const Container = styled(FlexColumn)`
  padding: 80px 64px;
  max-width: 1440px;

  @media (max-width: ${MobileSize}) {
    padding: 0 24px 96px;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(${props => props.backgroundHeight}px + 48px);
  background-image: linear-gradient(
    to bottom,
    rgba(252, 254, 255, 0.53),
    ${hexToRgba(COLORS.azure, '0.05')}
  );
  z-index: -1;

  transition: height 1s;

  @media (max-width: ${TabletSize}) {
    height: calc(${props => props.backgroundHeight}px + 72px);
  }

  @media (max-width: ${MobileSize}) {
    height: calc(${props => props.backgroundHeight}px + 80px);
  }
`;

const VerifyEmploymentContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 24px;

  @media (max-width: ${MobileSize}) {
    margin-bottom: 16px;
  }
`;

const HellosignAlertContainer = styled.div`
  margin-bottom: 24px;
`;

const SomethingMissingContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;

  @media (max-width: ${MobileSize}) {
    margin-top: 16px;
    margin-bottom: 0;
  }
`;

const TopContainer = styled(FlexColumn)`
  flex: 1 0 auto;
`;

const BottomContainer = styled(FlexRow)`
  @media (max-width: ${MobileSize}) {
    flex-direction: column;
  }
`;

const PslfHeader = styled.div`
  max-width: 492px;
  text-align: left;
  padding-right: 48px;

  @media (max-width: ${MobileSize}) {
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 16px;
  }
`;

const Divider = styled.div`
  border-top: 1px solid ${COLORS.grey};
  margin: 40px 0;

  @media (max-width: ${MobileSize}) {
    margin: 24px 0;
  }
`;

const PslfInfo = styled.div`
  max-width: 640px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const ListHeaderContainer = styled.div`
  &:first-child {
    margin-top: 10px;
  }

  margin-top: 16px;
  margin-bottom: 8px;
`;

const mapStateToProps = state => ({
  employerSignURL: get(state, 'signatureRequests[0]'),
  isLoading: isLoadingWithInit(state, 'fetchSignatureRequestJwt'),
  serverError: getErrorMessage(state, 'fetchSignatureRequestJwt'),
});

export default connect(mapStateToProps)(Platform(EmployerPageV2));
