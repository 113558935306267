import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  COLORS,
  FlexRow,
  Icon,
  ArrowUp,
  Strong,
  Collapse,
} from '@summer/ui-components';

import PlanCard from 'components/pages/idr/wizard/steps/ReviewYourPlan/NewRYP/PlanCard';
import { setIdrSelectedPathId } from 'redux/actions/idr.actions';
import { shouldUsePlaidFlow } from 'redux/selectors/plaid.selectors';

/**
 * A collapsible component that shows/hides other eligible plans
 * @param {Object} props
 * @param {React.ReactNode} props.children - The content to be collapsed
 * @returns {JSX.Element} Collapsible component for plan options
 */
const CollapsiblePlans = ({ children }) => {
  return (
    <Collapse
      openByDefault={false}
      trigger={({ openState }) => (
        <TriggerContainer>
          <CircleIcon>
            <Icon
              width={12}
              height={12}
              fill={COLORS.darkGrey}
              SvgComponent={ArrowUp}
              style={{
                transform: openState ? 'rotate(0deg)' : 'rotate(180deg)',
              }}
            />
          </CircleIcon>
          <StyledStrong>
            {openState ? 'Hide' : 'Show'} other eligible plans
          </StyledStrong>
        </TriggerContainer>
      )}
    >
      <div>{children}</div>
    </Collapse>
  );
};

CollapsiblePlans.propTypes = {
  children: PropTypes.node.isRequired,
};

const CurrentPlan = ({ isPslfOn, shouldUsePlaidFlow, currentPath }) => {
  let path = currentPath;
  let loansSource = null;

  if (shouldUsePlaidFlow) {
    // TODO: [ch30192] Remove after Python is updated to send null planType for loanSource of plaid
    path = {
      ...currentPath,
      planType: [],
    };
    loansSource = 'plaid';
  }

  return (
    <PlanCard
      key="current-path"
      isPslfOn={isPslfOn}
      path={path}
      isCurrentPath
      loansSource={loansSource}
    />
  );
};

const Plans = ({ isPslfOn, shouldUsePlaidFlow, paths, selectPath }) => {
  const { recommended, notRecommended } = paths;

  return (
    <Container>
      {recommended.map((path, idx) => (
        <PlanCard
          key={idx}
          isPslfOn={isPslfOn}
          path={path}
          showInsights={true}
          selectPath={() => selectPath(path.id)}
          loansSource={shouldUsePlaidFlow ? 'plaid' : null}
        />
      ))}
      {notRecommended.length > 0 && (
        <CollapsiblePlans>
          {notRecommended.map((path, idx) => (
            <PlanCard
              key={idx}
              data-testid={`plan-card-${path.id}`}
              isPslfOn={isPslfOn}
              path={path}
              showInsights={false}
              selectPath={() => selectPath(path.id)}
              loansSource={shouldUsePlaidFlow ? 'plaid' : null}
            />
          ))}
        </CollapsiblePlans>
      )}
    </Container>
  );
};

Plans.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  incrementSubmitCount: PropTypes.func.isRequired,
  selectPath: PropTypes.func.isRequired,
  shouldUsePlaidFlow: PropTypes.bool.isRequired,
};

const PlanOptions = ({
  isPslfOn,
  currentPath,
  paths,
  setIdrSelectedPathId,
  handleSubmit,
  incrementSubmitCount,
  shouldUsePlaidFlow,
}) => {
  return (
    <PlansContainer>
      <CurrentPlan
        isPslfOn={isPslfOn}
        currentPath={currentPath}
        shouldUsePlaidFlow={shouldUsePlaidFlow}
      />
      <Plans
        isPslfOn={isPslfOn}
        shouldUsePlaidFlow={shouldUsePlaidFlow}
        paths={paths}
        selectPath={pathId => {
          setIdrSelectedPathId(pathId);
          incrementSubmitCount();
          handleSubmit();
        }}
      />
    </PlansContainer>
  );
};

const PlansContainer = styled.div`
  max-width: 1024px;
  margin: auto;
`;

const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  margin: 24px auto;
  position: relative;
`;

const TriggerContainer = styled(FlexRow)`
  width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    background-color: ${COLORS.grey};
    width: calc(50% - 150px);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
`;

const CircleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-color: ${COLORS.darkGrey};
  border-width: 1px;
  border-style: solid;
  margin-right: 8px;
`;

const StyledStrong = styled(Strong)`
  color: ${COLORS.darkGrey};
`;

const mapStateToProps = state => ({
  shouldUsePlaidFlow: shouldUsePlaidFlow(state),
});

export default connect(mapStateToProps, { setIdrSelectedPathId })(PlanOptions);
