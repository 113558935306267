import React from 'react';
import styled from 'styled-components';

import { Header } from '@summer/ui-components/src';

import SecurityHeader from './SecurityHeader';

const HeaderContainer = styled.div`
  margin-bottom: 8px;
`;
/**
 * VerificationPageHeader component
 * @param {object} props
 * @param {string} props.verificationPageText
 * @returns {JSX.Element}
 */
export const VerificationPageHeader = ({ verificationPageText }) => {
  return (
    <>
      <SecurityHeader />

      <HeaderContainer>
        <Header h1 center>
          {verificationPageText}
        </Header>
      </HeaderContainer>
    </>
  );
};
