import { isNil, startCase, isEmpty } from 'lodash';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  Text,
  Header,
  FlexRow,
  FlexColumn,
  FlexCenter,
  Button,
  ResponsiveColumn,
  Row,
  COLORS,
  StarIcon,
  CurrencyDollar,
} from '@summer/ui-components';

import { IDR_APPLICATION_TYPES } from 'components/pages/idr/wizard/steps/ReviewYourPlan/constants';
import ConsolidationInfoPopup from 'components/pages/idr/wizard/steps/ReviewYourPlan/popups/ConsolidationInfoPopup';
import ZeroPaymentPopup from 'components/pages/idr/wizard/steps/ReviewYourPlan/popups/ZeroPaymentPopup';
import TextTooltip from 'components/shared/TextTooltip';
import { Link } from 'components/shared/typography';
import Statistic from 'components/shared/typography/Statistic';
import { SmallDesktopSize, MobileSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import { isLoading } from 'redux/selectors/ui.selectors';
import { hexToRgba } from 'utils/common';
import { calcRepaymentPeriod, renderPlanTypeNames } from 'utils/idr';

import Insights from './Insights';
import { planHighlightMap, planKeepInMindMap } from './planHighlightMap';

const { RECALCULATE, SWITCH, NEW, RECERTIFY } = IDR_APPLICATION_TYPES;

const recommendedReasonMap = {
  'Lowest monthly payment': 'Pay\u00A0Less\u00A0Now',
  'Lowest total amount paid': 'Pay\u00A0Less\u00A0Overall',
};

const PlanCardNumbers = ({
  isPlaidLoansAndCurrentPlan,
  isCurrentPath,
  firstPayment,
  amountForgivenTooltipText,
  repaymentPeriodTooltipText,
  amountForgiven,
  repaymentPeriod,
  totalPayment,
}) => {
  const { isDesktop } = usePlatform();
  return (
    <Row desktopEnd tabletEnd fullWidth>
      <ResponsiveDataColumn
        mobileCol={isPlaidLoansAndCurrentPlan ? 12 : 6}
        tabletCol={3}
        desktopCol={3}
      >
        <ColContent>
          <Text size="small">
            <TextTooltip
              lightUnderline
              text={
                isCurrentPath
                  ? 'This is the estimated amount that you are paying under these plan(s).'
                  : 'If you enroll in this plan, this is the amount you would pay per month for the next year, until you recertify your income. Summer will remind you when it’s time to recertify.'
              }
            >
              {isCurrentPath ? 'Monthly payment' : 'Next 12 payments'}
            </TextTooltip>
          </Text>
          <StatisticWithDollarSignWrapper>
            {!isCurrentPath && isDesktop && (
              <CurrencyDollarWrapper>
                <DollarIcon />
              </CurrencyDollarWrapper>
            )}
            <StatisticWrapper>
              <IdrStatistic
                bold
                size="small"
                animate
                value={firstPayment}
                format="dollars"
                testId="monthly-payment"
              />
            </StatisticWrapper>
          </StatisticWithDollarSignWrapper>
        </ColContent>
      </ResponsiveDataColumn>
      <ResponsiveDataColumn
        hide={isPlaidLoansAndCurrentPlan}
        mobileCol={6}
        tabletCol={3}
        desktopCol={3}
        mobileEnd
      >
        <ColContent>
          <Text size="small">
            <TextTooltip lightUnderline text={amountForgivenTooltipText}>
              Potential forgiveness
            </TextTooltip>
          </Text>
          <IdrStatistic
            bold
            size="small"
            animate
            value={amountForgiven}
            format="dollars"
          />
        </ColContent>
      </ResponsiveDataColumn>

      <ResponsiveDataColumn
        hide={isPlaidLoansAndCurrentPlan}
        mobileCol={6}
        tabletCol={3}
        desktopCol={3}
      >
        <ColContent>
          <Text size="small">
            <TextTooltip lightUnderline text={repaymentPeriodTooltipText}>
              Maximum term
            </TextTooltip>
          </Text>
          <IdrStatistic
            bold
            size="small"
            animate
            value={repaymentPeriod}
            format="number"
          />
        </ColContent>
      </ResponsiveDataColumn>

      <ResponsiveDataColumn
        hide={isPlaidLoansAndCurrentPlan}
        mobileCol={6}
        tabletCol={3}
        desktopCol={3}
        mobileEnd
      >
        <ColContent>
          <Text size="small">
            <TextTooltip
              lightUnderline
              text="This is the total projected amount that you could pay over the life of your loans while enrolled in this plan. If your monthly payment is more than zero dollars a month, this is based on the assumption that your income will grow 3% each year."
            >
              Total to repay
            </TextTooltip>
          </Text>
          <IdrStatistic
            bold
            size="small"
            animate
            value={totalPayment}
            format="dollars"
          />
        </ColContent>
      </ResponsiveDataColumn>
    </Row>
  );
};

const showPopupLinks = (requiresConsolidation, isZeroPaymentInfoShown) =>
  (requiresConsolidation || isZeroPaymentInfoShown) && (
    <PopupLinksContainer>
      {requiresConsolidation && (
        <ConsolidationInfoPopup>
          <Text color="secondary" small>
            <Link inheritColor underline>
              Why do I need to consolidate?
            </Link>
          </Text>
        </ConsolidationInfoPopup>
      )}
      {isZeroPaymentInfoShown && (
        <ZeroPaymentPopup>
          <Text color="secondary" small>
            <Link inheritColor underline>
              Could I really pay $0?
            </Link>
          </Text>
        </ZeroPaymentPopup>
      )}
    </PopupLinksContainer>
  );

/**
 * A card component that displays details about an IDR repayment plan option
 * @param {Object} props
 * @param {boolean} props.isPslfOn - Whether PSLF is enabled for this plan
 * @param {Object} props.path - The repayment plan path data
 * @param {boolean} props.isCurrentPath - Whether this is the user's current plan
 * @param {boolean} props.isSelectedPath - Whether this plan is selected
 * @param {string} props.platformType - The platform type (mobile/desktop)
 * @param {Function} props.selectPath - Callback when plan is selected
 * @param {boolean} props.idrIsLoading - Loading state for IDR data
 * @param {string} props.loansSource - Source of loans data ('plaid' or null)
 * @param {number} props.idx - Index of the plan in the list
 * @param {boolean} props.renderInsights - Whether to render plan insights
 * @param {boolean} props.showInsights - Whether to show plan insights
 * @returns {JSX.Element} The plan card component
 */

const PlanCard = ({
  isPslfOn,
  path,
  isCurrentPath,
  isSelectedPath,
  selectPath,
  idrIsLoading,
  loansSource,
  renderInsights = true,
  showInsights = false,
}) => {
  const {
    amountForgiven,
    firstPayment,
    numPayments,
    totalPayment,
    appType,
    requiresConsolidation,
    planType,
    recommendedReasons,
    recommended,
  } = path;

  const { isMobile, isTablet, isDesktop, isSmallDesktop } = usePlatform();

  const headerPrefix = {
    [NEW]: requiresConsolidation
      ? 'Consolidate and enroll in the '
      : 'Enroll in the ',
    [SWITCH]: requiresConsolidation
      ? 'Consolidate and switch to the '
      : 'Switch to the ',
    [RECALCULATE]: 'Recalculate my ',
    [RECERTIFY]: 'Recertify my ',
  };

  const showActivePath = isCurrentPath || isSelectedPath;
  const isZeroPaymentInfoShown = !showActivePath && firstPayment === 0;

  const repaymentPeriod = isNil(numPayments)
    ? null
    : calcRepaymentPeriod(numPayments);

  const planOrPlans = `plan${planType.length > 1 ? 's' : ''}`;

  const isLoanSourcePlaid = loansSource === 'plaid';
  const isCurrentPlan = isEmpty(planType);
  const isPlaidLoansAndCurrentPlan = isLoanSourcePlaid && isCurrentPlan;

  const pslfWaiverCopy =
    'if you received credit for additional payments under the PSLF Limited Waiver';

  const pslfWaiverCopyCapitalized =
    pslfWaiverCopy.charAt(0).toUpperCase() + pslfWaiverCopy.slice(1);

  const amountForgivenTooltipText = `This is the amount that could be forgiven after the ${
    isPslfOn
      ? `10 year repayment period for Public Service Loan Forgiveness. ${pslfWaiverCopyCapitalized}, you may be eligible for forgiveness sooner than we estimate.`
      : '20 or 25 year repayment period with qualifying payments.'
  }`;

  const repaymentPeriodTooltipText = `This is the potential length of your repayment period until you pay off your loans or receive forgiveness. If you\u2019ve already been making payments, your repayment period will be shorter${
    isPslfOn
      ? `, and ${pslfWaiverCopy}, you may be eligible for forgiveness sooner than this estimate.`
      : '.'
  }`;

  const buildInsights = useCallback(() => {
    if (!renderInsights) {
      return null;
    }

    const [plan] = planType;

    const planHighlights = planHighlightMap.get(plan);
    const planKeepInMind = planKeepInMindMap.get(plan);

    if (!planHighlights && !planKeepInMind) {
      return null;
    }

    return (
      <Insights
        highlights={planHighlights}
        keepInMind={planKeepInMind}
        defaultOpen={showInsights}
      />
    );
  }, [renderInsights, planType, showInsights]);

  const buildBadges = useCallback(() => {
    const recBadgeText = () => {
      if (isSelectedPath) {
        return startCase(`Selected Plan`);
      }

      if (isCurrentPath) {
        return startCase(`Your current ${planOrPlans}`);
      }

      return recommendedReasonMap[recommendedReasons[0]];
    };

    const badges = [];
    if (isSelectedPath || showActivePath) {
      return [
        <RecBadge
          key={`rec-badge-${planType}`}
          color={COLORS.white}
          showActivePath={showActivePath}
        >
          <Text paragraph size="small" bold>
            {recBadgeText()}
          </Text>
        </RecBadge>,
      ];
    }

    if (recommended) {
      badges.push(
        <RecBadge
          showActivePath={showActivePath}
          color={COLORS.lightPink}
          key={`rec-badge-${planType}`}
        >
          <Star />
          <Text paragraph size="small" bold>
            Recommended
          </Text>
        </RecBadge>,
      );
    }

    return [
      ...badges,
      recommendedReasons.map((reason, idx) => (
        <RecBadge
          showActivePath={showActivePath}
          color={hexToRgba(COLORS.jade, 0.15)}
          key={`rec-badge-recommended-${idx}`}
        >
          <Text paragraph size="small" bold>
            {recommendedReasonMap[reason]}
          </Text>
        </RecBadge>
      )),
    ];
  }, [
    isSelectedPath,
    showActivePath,
    planType,
    recommended,
    recommendedReasons,
    isCurrentPath,
    planOrPlans,
  ]);

  const badges = buildBadges();

  const showEnrollNowButton = selectPath && !isCurrentPath;

  const isSmallScreen = isMobile || isTablet;
  const isBiggerScreen = isDesktop || isSmallDesktop;
  const showBottomRowPlanCardNumbers = !isCurrentPath || isSmallScreen;

  return (
    <PlanCardContainer>
      <Background showActivePath={showActivePath}>
        <Row>
          <ResponsiveColumn mobileCol={12} tabletCol={12} desktopCol={4}>
            <HeaderContent>
              {badges.length > 0 && <Badges>{badges}</Badges>}
              <Header h2>
                {headerPrefix[appType]}
                {renderPlanTypeNames(planType)}
              </Header>
            </HeaderContent>
          </ResponsiveColumn>
          <ResponsiveDataColumnFlexEnd
            mobileCol={12}
            tabletCol={12}
            desktopCol={8}
          >
            {isCurrentPath && isBiggerScreen && (
              <PlanCardNumbersContainer>
                <PlanCardNumbers
                  isPlaidLoansAndCurrentPlan={isPlaidLoansAndCurrentPlan}
                  isCurrentPath={isCurrentPath}
                  firstPayment={firstPayment}
                  amountForgivenTooltipText={amountForgivenTooltipText}
                  repaymentPeriodTooltipText={repaymentPeriodTooltipText}
                  amountForgiven={amountForgiven}
                  repaymentPeriod={repaymentPeriod}
                  totalPayment={totalPayment}
                />
              </PlanCardNumbersContainer>
            )}
            {showEnrollNowButton && isDesktop && (
              <CTAContainer isDesktop>
                <Button
                  width={160}
                  onClick={selectPath}
                  type="submit"
                  isLoading={idrIsLoading}
                >
                  Enroll now
                </Button>
              </CTAContainer>
            )}
          </ResponsiveDataColumnFlexEnd>
        </Row>
        {showBottomRowPlanCardNumbers && (
          <BottomRow
            showActivePath={showActivePath}
            data-testid={`idr-plan-card-${planType?.join()}-${appType}`}
            flexRowMobile={isPlaidLoansAndCurrentPlan}
          >
            <Row>
              <ResponsiveColumn mobileCol={0} tabletCol={0} desktopCol={4} />
              <ResponsiveColumn mobileCol={12} tabletCol={12} desktopCol={8}>
                <PlanCardNumbersContainer>
                  <PlanCardNumbers
                    isPlaidLoansAndCurrentPlan={isPlaidLoansAndCurrentPlan}
                    isCurrentPath={isCurrentPath}
                    firstPayment={firstPayment}
                    amountForgivenTooltipText={amountForgivenTooltipText}
                    repaymentPeriodTooltipText={repaymentPeriodTooltipText}
                    amountForgiven={amountForgiven}
                    repaymentPeriod={repaymentPeriod}
                    totalPayment={totalPayment}
                  />
                </PlanCardNumbersContainer>
              </ResponsiveColumn>
            </Row>

            {showEnrollNowButton && (isSmallScreen || isSmallDesktop) && (
              <CTAContainer>
                <Button
                  width={160}
                  onClick={selectPath}
                  type="submit"
                  isLoading={idrIsLoading}
                >
                  Enroll now
                </Button>
              </CTAContainer>
            )}
            {showPopupLinks(requiresConsolidation, isZeroPaymentInfoShown)}
          </BottomRow>
        )}
      </Background>
      {!isCurrentPath && <>{buildInsights()}</>}
    </PlanCardContainer>
  );
};

const ResponsiveDataColumnFlexEnd = styled(ResponsiveColumn)`
  justify-content: center;
  display: flex;
`;

const PlanCardNumbersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 16px;
  width: 100%;
`;

const PlanCardContainer = styled.div`
  margin-bottom: 16px;
  width: 100%;
`;

const Background = styled.div`
  background-color: ${({ showActivePath }) =>
    showActivePath ? COLORS.lighterGrey : COLORS.white};
  border-radius: 3px;
  box-shadow: ${({ showActivePath }) =>
    showActivePath ? 'none' : '0 2px 10px 0 #d7dbe1'};
  border: ${({ showActivePath }) =>
    showActivePath ? 'none' : 'solid 1px rgba(223, 227, 232, 0.29)'};
  padding: 24px;
`;

const BottomRow = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 0;

  @media (max-width: ${SmallDesktopSize}) {
    flex-direction: ${({ flexRowMobile }) =>
      flexRowMobile ? 'row' : 'column'};
  }
`;

const HeaderContent = styled(FlexColumn)`
  min-width: 360px;

  margin-right: 16px;

  @media (max-width: ${SmallDesktopSize}) {
    min-width: unset;
    max-width: unset;
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  @media (max-width: ${MobileSize}) {
    margin-bottom: 8px;
  }
`;

const IdrStatistic = styled(Statistic)`
  margin-bottom: 4px;
`;

const Badges = styled(FlexRow)`
  align-items: center;
  margin-bottom: 8px;
  width: 100%;

  & > div:first-child {
    margin-right: 10px;
  }
`;

const RecBadge = styled(FlexCenter)`
  padding: 5px 8px;
  border-radius: 3px;
  margin-right: 12px;

  background-color: ${({ color }) => color};
`;

const PopupLinksContainer = styled(FlexColumn)`
  & > div:not(:last-child) {
    margin-bottom: 10px;
  }

  @media (max-width: ${SmallDesktopSize}) {
    margin-top: 8px;
  }

  @media (max-width: ${MobileSize}) {
    margin-top: 16px;
  }
`;

const ResponsiveDataColumn = styled(ResponsiveColumn)`
  display: ${({ hide }) => (hide ? 'none' : 'auto')};
`;

const ColContent = styled.div`
  & > div:first-child {
    margin-bottom: 8px;
  }

  @media (max-width: ${MobileSize}) {
    margin-top: 8px;
  }
`;

const StatisticWithDollarSignWrapper = styled.div``;

const CurrencyDollarWrapper = styled.div`
  float: left;
  display: inline-block;
  margin-left: 16px;
`;

const StatisticWrapper = styled.div`
  float: right;
  display: inline-block;
  @media (max-width: ${MobileSize}) {
    float: left;
  }
`;

const CTAContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: ${({ isDesktop }) => (isDesktop ? '16px' : '0')};
  max-height: 50px;

  @media (max-width: ${MobileSize}) {
    width: 100%;
  }
`;

const Star = styled(StarIcon)`
  margin-right: 4px;
  & > path {
    fill: ${COLORS.darkPink};
  }
`;

const DollarIcon = styled(CurrencyDollar)`
  g {
    fill: ${COLORS.white};
  }
  background-color: ${COLORS.covidGreen};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 3px;
  margin-left: 6px;
`;

const mapStateToProps = state => {
  return {
    idrIsLoading:
      isLoading(state, 'updateCurrentIdrForm') ||
      isLoading(state, 'fetchIdrPathOptions') ||
      isLoading(state, 'fetchLoans'),
  };
};

export default connect(mapStateToProps)(PlanCard);
