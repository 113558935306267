import React from 'react';

import { Alert } from '@summer/ui-components';

const CertificationAlert = () => {
  return (
    <Alert theme="neutral" dismissable={false}>
      What&rsquo;s next: your employment approver will review and sign your
      certification. Following this, the Summer team will review and notify you
      of any updates via email in the coming weeks.
    </Alert>
  );
};

export default CertificationAlert;
