export const trackingEvents = {
  ONBOARDING_PAGE_VIEWED: 'eduAssist:onboarding_viewed',
  EDU_ASSIST: {
    reimbursement: {
      LANDING_PAGE_VIEWED: 'eduAssist:TRLandingPage_view',
      GET_STARTED_CLICK: 'eduAssist:TRLandingPage_click',
      COURSE_REQUEST_FORM_VIEWED: 'eduAssist:TRTellUsAboutYourCourse_view',
      COURSE_REQUEST_UPLOAD_FILE_CLICKED:
        'eduAssist:TRTellUsAboutYourCourseUploadFile_click',
      COURSE_REQUEST_UPLOAD_FILE_UPLOADED:
        'eduAssist:TRTellUsAboutYourCourseUploadFile_upload',
      COURSE_REQUEST_ELIG_POLICY_CLICKED:
        'eduAssist:TRTellUsAboutYourCourseEligPolicy_click',
      COURSE_REQUEST_POLICY_ACKN_CLICKED:
        'eduAssist:TRTellUsAboutYourCoursePolicyAckn_click',
      COURSE_REQUEST_SUBMIT: 'eduAssist:TRTellUsAboutYourCourse_submit',
      COURSE_REQUEST_CANCEL_CLICKED:
        'eduAssist:TRTellUsAboutYourCourseCancel_click',
      COURSE_REQUEST_SUBMIT_MODAL_VIEWED:
        'eduAssist:TRCourseApprovalModal_view',
      COURSE_REQUEST_SUBMIT_MODAL_CLICKED:
        'eduAssist:TRCourseApprovalModal_click',
      DETAILS_MODAL_VIEWED: 'eduAssist:TRRequestModal_viewed',
      CREATE_NEW_REQUEST_CLICK: 'eduAssist:TRAddNewRequest_click',
      ADD_PROOF_BUTTON_CLICKED: 'eduAssist:TRAddProofOfCompletion_click',
      ADD_PROOF_FORM_VIEWED: 'eduAssist:TRAddProofOfCompletion_view',
      ADD_PROOF_RECEIPT_FILE_CLICKED:
        'eduAssist:TRProvideProofofCompletionAndReceiptReceiptUpload_click',
      ADD_PROOF_RECEIPT_FILE_UPLOADED:
        'eduAssist:TRProvideProofofCompletionAndReceiptReceiptUpload_upload',
      ADD_PROOF_COMPLETION_FILE_CLICKED:
        'eduAssist:TRProvideProofOfCompletionAndReceiptProofOfCompletionUpload_click',
      ADD_PROOF_COMPLETION_FILE_UPLOADED:
        'eduAssist:TRProvideProofOfCompletionAndReceiptProofOfCompletionUpload_upload',
      ADD_PROOF_SUBMIT: 'eduAssist:TRProvideProofOfCompletionAndReceipt_submit',
      ADD_PROOF_SUBMIT_MODAL_VIEWED: 'eduAssist:TRProofOfCompletionModal_view',
      ADD_PROOF_SUBMIT_MODAL_CLICKED:
        'eduAssist:TRProofOfCompletionModal_click',
    },
    assistance: {
      LANDING_PAGE_VIEWED: 'eduAssist:TALandingPage_view',
      GET_STARTED_CLICK: 'eduAssist:TALandingPage_click',
      COURSE_REQUEST_FORM_VIEWED: 'eduAssist:TATellUsAboutYourCourse_view',
      COURSE_REQUEST_UPLOAD_FILE_CLICKED:
        'eduAssist:TATellUsAboutYourCourseUploadFile_click',
      COURSE_REQUEST_UPLOAD_FILE_UPLOADED:
        'eduAssist:TATellUsAboutYourCourseUploadFile_upload',
      COURSE_REQUEST_ELIG_POLICY_CLICKED:
        'eduAssist:TATellUsAboutYourCourseEligPolicy_click',
      COURSE_REQUEST_POLICY_ACKN_CLICKED:
        'eduAssist:TATellUsAboutYourCoursePolicyAckn_click',
      COURSE_REQUEST_SUBMIT: 'eduAssist:TATellUsAboutYourCourse_submit',
      COURSE_REQUEST_CANCEL_CLICKED:
        'eduAssist:TATellUsAboutYourCourseCancel_click',
      COURSE_REQUEST_SUBMIT_MODAL_VIEWED:
        'eduAssist:TACourseApprovalModal_view',
      COURSE_REQUEST_SUBMIT_MODAL_CLICKED:
        'eduAssist:TACourseApprovalModal_click',
      DETAILS_MODAL_VIEWED: 'eduAssist:TARequestModal_viewed',
      CREATE_NEW_REQUEST_CLICK: 'eduAssist:TAAddNewRequest_click',
      ADD_PROOF_BUTTON_CLICKED: 'eduAssist:TAAddProofOfCompletion_click',
      ADD_PROOF_FORM_VIEWED: 'eduAssist:TAAddProofOfCompletion_view',
      ADD_PROOF_RECEIPT_FILE_CLICKED:
        'eduAssist:TAProvideProofofCompletionAndReceiptReceiptUpload_click',
      ADD_PROOF_RECEIPT_FILE_UPLOADED:
        'eduAssist:TAProvideProofofCompletionAndReceiptReceiptUpload_upload',
      ADD_PROOF_COMPLETION_FILE_CLICKED:
        'eduAssist:TAProvideProofOfCompletionAndReceiptProofOfCompletionUpload_click',
      ADD_PROOF_COMPLETION_FILE_UPLOADED:
        'eduAssist:TAProvideProofOfCompletionAndReceiptProofOfCompletionUpload_upload',
      ADD_PROOF_SUBMIT: 'eduAssist:TAProvideProofOfCompletionAndReceipt_submit',
      ADD_PROOF_SUBMIT_MODAL_VIEWED: 'eduAssist:TAProofOfCompletionModal_view',
      ADD_PROOF_SUBMIT_MODAL_CLICKED:
        'eduAssist:TAProofOfCompletionModal_click',
    },
  },
  UNIVERSAL_LOGIN: {
    LANDING_PAGE_VIEWED: 'onboarding:UniversalLoginSignUp_LandingPage_view',
    ACTIVATION_CODE_CLICKED:
      'onboarding:UniversalLoginSignUp_LandingPageActivationCode_click',
    LANDING_CONTINUE_CLICKED:
      'onboarding:UniversalLoginSignUp_LandingPage_click',
    AUTH_FORM_VIEWED: 'onboarding:UniversalLoginSignUp_LoginPage_view',
  },
};
