import { Formik } from 'formik';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

import {
  Button,
  COLORS,
  Header,
  TextInput,
  AirBalloon,
  Icon,
  Strong,
  Text,
  Alert,
  FlexCenter,
  FlexRow,
  Pencil2,
  textColors,
} from '@summer/ui-components';

import OAuthBox from 'components/pages/auth/Login/OAuthBox';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { MobileSize } from 'constants/styleguide';
import { trackingEvents } from 'constants/trackingEvents';
import { AuthMechanism } from 'constants/user';
import { checkUserLoginMethod } from 'redux/actions/auth.actions';
import { clearServerError } from 'redux/actions/ui.actions';
import { getErrorCode, isLoading } from 'redux/selectors/ui.selectors';
import { universalWelcomeSchema } from 'schemas/onboard';
import { track } from 'utils/track';

import PasswordLoginMethod from './authMethods/PasswordLoginMethod';

const enterDurationMs = 750;
const exitDurationMs = 750;

const errorMessages = new Map([
  [
    404,
    `Unfortunately, we couldn't find an account associated with your email.`,
  ],
  [429, `You've made too many requests, please try again later.`],
]);

const getClientErrorMessage = errorCode => {
  return errorMessages.get(errorCode);
};

const AdpAuthMessage = () => {
  return (
    <Alert testId="adp-auth-component" dismissable={false} showIcon={false}>
      <Text paragraph>
        To access Summer, sign into{' '}
        <Link href="https://My.ADP.com"> My.ADP.com</Link>, then select
        Retirement &gt; Manage your account &gt; Student Loan Matching &gt;
        Enroll in Summer &gt; Go to Summer.
      </Text>
    </Alert>
  );
};

const CkAuthMessage = () => {
  return (
    <Alert
      testId="ck-auth-component"
      theme="warning"
      dismissable={false}
      showIcon={false}
    >
      <Text paragraph>
        <span role="img" aria-label="emoji">
          😔
        </span>{' '}
        Unfortunately, our partnership with your employer{' '}
        <Strong>Credit Karma </Strong> is expired and you can no longer login.
      </Text>
    </Alert>
  );
};

const FallbackAuthMessage = () => (
  <Alert theme="warning" dismissable={false} showIcon={false}>
    <Text paragraph>
      We are unable to log you in to your account. Please contact us at{' '}
      <EmailLink href={`mailto:${supportAddress}`} inheritColor underline>
        {supportAddress}
      </EmailLink>
      .
    </Text>
  </Alert>
);

const AuthMethodComponents = ({ authMethod, email, oidcProvider }) => {
  if (authMethod === AuthMechanism.adp) {
    return <AdpAuthMessage />;
  }

  if (authMethod === AuthMechanism.creditkarma) {
    return <CkAuthMessage />;
  }

  if (
    authMethod === AuthMechanism.cityOfAlexandria ||
    authMethod === AuthMechanism.google ||
    authMethod === AuthMechanism.facebook ||
    authMethod === AuthMechanism.oidc
  ) {
    return <OAuthBox provider={authMethod} oidcProvider={oidcProvider} />;
  }

  if (authMethod === AuthMechanism.password) {
    return <PasswordLoginMethod email={email} />;
  }

  return <FallbackAuthMessage />;
};

const useTrackEvents = ({
  userAuthMethod,
  submittedEmail,
  viewingAuthForm,
  errorCode,
}) => {
  useEffect(() => {
    track(trackingEvents.UNIVERSAL_LOGIN.LANDING_PAGE_VIEWED);
  }, []);

  useEffect(() => {
    if (errorCode) {
      track(trackingEvents.UNIVERSAL_LOGIN.LANDING_CONTINUE_CLICKED, {
        userAuthMethod,
        submittedEmail,
        errorReason: getClientErrorMessage(errorCode),
        errorType: errorCode,
      });
    }
  }, [errorCode, submittedEmail, userAuthMethod]);

  useEffect(() => {
    if (viewingAuthForm) {
      track(trackingEvents.UNIVERSAL_LOGIN.AUTH_FORM_VIEWED, {
        userAuthMethod,
        submittedEmail,
      });
    }
  }, [userAuthMethod, submittedEmail, viewingAuthForm]);
};

const UniversalWelcome = ({ isLoading, errorCode }) => {
  const dispatch = useDispatch();
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [userAuthMethod, setUserAuthMethod] = useState(null);
  const [userOidcProvider, setUserOidcProvider] = useState(null);

  const onSuccess = response => {
    const { method, oidcProvider } = response;
    track(trackingEvents.UNIVERSAL_LOGIN.LANDING_CONTINUE_CLICKED, dispatch);
    setUserAuthMethod(method);
    setUserOidcProvider(oidcProvider);
  };

  const viewingAuthForm = useMemo(() => {
    return !!userAuthMethod;
  }, [userAuthMethod]);

  useTrackEvents({
    userAuthMethod,
    submittedEmail,
    viewingAuthForm,
    errorCode,
    isLoading,
  });

  const resetSubmission = () => {
    dispatch(clearServerError({ label: 'checkUserLoginMethod' }));
    dispatch(clearServerError({ label: 'login' }));
    setUserAuthMethod(null);
  };

  const handleSubmit = values => {
    setSubmittedEmail(values.email);
    dispatch(checkUserLoginMethod(values.email, { onSuccess }));
    document.getElementById('email-input').blur();
  };

  useEffect(() => {
    if (!userAuthMethod) {
      document.getElementById('email-input').focus();
    }
  }, [userAuthMethod]);

  const renderForm = formik => {
    const {
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      touched,
      isSubmitting,
      isValid,
    } = formik;

    return (
      <TransitionGroup>
        {!userAuthMethod && (
          <CSSTransition
            in={!userAuthMethod}
            classNames="emailForm"
            timeout={{
              enter: enterDurationMs,
              exit: exitDurationMs,
            }}
          >
            <Form onSubmit={handleSubmit}>
              <TextInput
                label="Email"
                name="email"
                touched={touched.email}
                isSubmitting={isSubmitting}
                error={errors.email}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={resetSubmission}
                inputMode="email"
                disabled={!!userAuthMethod}
              />

              <ContinueButton
                disabled={!isValid}
                isLoading={isLoading}
                width={360}
                type="submit"
              >
                Continue
              </ContinueButton>
              {errorCode && (
                <Box>
                  <Alert theme="warning" dismissable={false} showIcon={false}>
                    <Text paragraph>{getClientErrorMessage(errorCode)}</Text>
                  </Alert>
                </Box>
              )}
              <Alert dismissable={false} showIcon={false}>
                <Text paragraph>
                  <span role="img" aria-label="emoji">
                    ✉️
                  </span>{' '}
                  <Strong>Have questions about signing in?</Strong> Please check
                  your email for access from your employer or organization or
                  contact us at{' '}
                  <EmailLink
                    href={`mailto:${supportAddress}`}
                    inheritColor
                    underline
                  >
                    {supportAddress}
                  </EmailLink>
                  .
                </Text>
              </Alert>
            </Form>
          </CSSTransition>
        )}
      </TransitionGroup>
    );
  };

  return (
    <Container>
      <AirBalloonIcon
        SvgComponent={AirBalloon}
        stroke={COLORS.azure}
        width={34}
        height={49}
      />
      <HeaderBox>
        <Header center>Welcome</Header>
      </HeaderBox>
      <AuthContainer>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={universalWelcomeSchema}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {renderForm}
        </Formik>

        <TransitionGroup>
          {userAuthMethod && (
            <CSSTransition
              in={!!userAuthMethod}
              classNames="authMethod"
              timeout={{
                enter: enterDurationMs,
                exit: exitDurationMs,
              }}
            >
              <AuthMethodWrapper>
                <EditEmailButton
                  onClick={resetSubmission}
                  aria-label="edit-email"
                >
                  {submittedEmail}
                  <Icon
                    SvgComponent={Pencil2}
                    fill={COLORS.azure}
                    width={20}
                    height={20}
                  />
                </EditEmailButton>
                <AuthMethodComponents
                  authMethod={userAuthMethod}
                  email={submittedEmail}
                  oidcProvider={userOidcProvider}
                />
              </AuthMethodWrapper>
            </CSSTransition>
          )}
        </TransitionGroup>
      </AuthContainer>
    </Container>
  );
};

const Container = styled(FlexCenter)`
  max-width: 408px;
  width: 100%;

  padding: 0 24px 72px;
  margin-top: 48px;

  flex-shrink: 0;
  flex-direction: column;
`;

const AuthContainer = styled.div`
  position: relative;
  margin-bottom: 24px;
  width: 100%;
`;

const HeaderBox = styled.div`
  margin-bottom: 48px;
`;

const EditEmailButton = styled.button`
  position: relative;
  width: 100%;
  height: 48px;
  margin-top: 27px;
  margin-bottom: 24px;
  padding-left: 16px;
  padding-right: 36px;
  border: 1px solid ${COLORS.grey};
  border-radius: 3px;

  background-color: ${COLORS.lightestGrey};
  color: ${textColors.default};

  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: pointer;

  .icon {
    position: absolute;
    top: 14px;
    right: 14px;
  }
`;

const Box = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Form = styled(FlexRow.withComponent('form'))`
  position: absolute;
  top: 0;
  flex-wrap: wrap;
  width: 100%;

  &.emailForm-enter {
    opacity: 0;
  }

  &.emailForm-enter-active {
    opacity: 1;
    transition: opacity ${enterDurationMs}ms ease;
  }

  &.emailForm-exit {
    opacity: 1;
  }

  &.emailForm-exit-active {
    opacity: 0;
    transition: opacity ${exitDurationMs}ms ease;
  }
`;

const AirBalloonIcon = styled(Icon)`
  margin-bottom: 24px;
`;

const EmailLink = styled(Link)`
  @media (max-width: ${MobileSize}) {
    word-wrap: break-word;
  }
`;

const ContinueButton = styled(Button)`
  margin-bottom: 24px;
`;

const AuthMethodWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding-bottom: 72px;

  &.authMethod-enter {
    opacity: 0;
  }

  &.authMethod-enter-active {
    opacity: 1;
    transition: opacity ${enterDurationMs}ms ease;
  }

  &.authMethod-exit {
    opacity: 1;
    pointer-events: none;
  }

  &.authMethod-exit-active {
    opacity: 0;
    transition: opacity ${exitDurationMs}ms ease;
  }
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'checkUserLoginMethod'),
  errorCode: getErrorCode(state, 'checkUserLoginMethod'),
});

export default connect(mapStateToProps, { clearServerError })(UniversalWelcome);
