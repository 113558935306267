import { isNil, toNumber, isNaN } from 'lodash';

import { formatDollar } from '@simplifidev/shared/dist/utils/formatter';

// These utils are duplicated in ui-components;
// changes must be made in both locations until they are consolidated
export const dollars = (
  amount,
  { shouldRound = true, showSymbol = true } = {},
) => {
  if (typeof amount !== 'number') {
    return amount;
  }

  if (showSymbol) {
    return formatDollar(amount / 100, {
      showSymbol: true,
      fractionDigits: shouldRound ? 0 : 2,
    });
  }

  return formatDollar(amount / 100, {
    fractionDigits: shouldRound ? 0 : 2,
    showSymbol: false,
  });
};

export const percentage = (
  amount,
  { precision = 2, showSymbol = true } = {},
) => {
  if (typeof amount !== 'number') {
    return amount;
  }
  const formattedPercentage = amount
    ? (amount * 100).toFixed(precision).toString()
    : '0';
  return showSymbol ? `${formattedPercentage}%` : formattedPercentage;
};

/* This function will convert a string to a number.
 * If passed null or empty string it will return null instead of 0
 * If number conversion produces NaN it will return null
 */
export const safeStringToNumber = string => {
  if (isNil(string) || string === '') {
    return null;
  }
  const number = toNumber(string);
  if (isNaN(number)) {
    return null;
  }
  return number;
};
