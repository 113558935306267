import { push as redirect } from 'connected-react-router';
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  AirBalloon,
  Alert,
  Button,
  COLORS,
  FlexCenter,
  FlexRow,
  Header,
  Icon,
  Strong,
  Text,
  TextInput,
} from '@summer/ui-components';

import GlobalError from 'components/shared/GlobalError';
import OAuthButtons from 'components/shared/OAuthButtons';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { MobileSize } from 'constants/styleguide';
import { login } from 'redux/actions/user.actions';
import { getErrorMessage, isLoading } from 'redux/selectors/ui.selectors';
import { loginValidationSchema } from 'schemas/onboard';
import { enableDTC } from 'services/featureFlags/flags';
import { scrollToError } from 'utils/formik';

const defaultOauthProviders = ['google', 'facebook', 'cityOfAlexandria'];

class Login extends React.Component {
  constructor() {
    super();

    this.formInitialValues = {
      email: '',
      password: '',
    };
  }

  handleSubmit = values => {
    const { email, password } = values;
    const { state } = this.props.location;

    this.props.login(
      {
        email,
        password,
      },
      state,
    );
  };

  renderForm = formik => {
    const {
      values,
      errors,
      handleChange,
      handleBlur,
      handleSubmit,
      touched,
      isSubmitting,
      isValid,
    } = formik;

    return (
      <Form onSubmit={handleSubmit}>
        <Box>
          <TextInput
            label="Email"
            name="email"
            touched={touched.email}
            isSubmitting={isSubmitting}
            error={errors.email}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            inputMode="email"
          />
        </Box>
        <Box>
          <TextInput
            label="Password"
            type="password"
            name="password"
            touched={touched.password}
            isSubmitting={isSubmitting}
            error={errors.password}
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="current-password"
          />
        </Box>
        <GlobalError label="login" />
        <Button
          fakeDisabled={!isValid}
          isLoading={this.props.isLoading}
          width={360}
          type="submit"
          onClick={() => scrollToError(errors)}
        >
          Sign in
        </Button>
      </Form>
    );
  };

  render() {
    const oauthProviders = [
      ...defaultOauthProviders,
      ...(this.props.showCheggLoginButton ? ['chegg'] : []),
    ];

    return (
      <Container>
        <AirBalloonIcon
          SvgComponent={AirBalloon}
          stroke={COLORS.azure}
          width={34}
          height={49}
        />
        <HeaderBox>
          <Header center>Welcome back!</Header>
        </HeaderBox>

        <OAuthButtons type="login" oauthProviders={oauthProviders} />

        <Formik
          initialValues={this.formInitialValues}
          validationSchema={loginValidationSchema}
          onSubmit={this.handleSubmit}
        >
          {formik => this.renderForm(formik)}
        </Formik>

        <LinkBox>
          <Text>
            <Link to="/forgot" inheritColor underline>
              Forgot your password?
            </Link>
          </Text>
        </LinkBox>
        {enableDTC() ? (
          <Link to="onboard/create-account">
            <Button secondary>New here? Create an account</Button>
          </Link>
        ) : (
          <Alert dismissable={false} showIcon={false}>
            <Text paragraph>
              <span role="img" aria-label="emoji">
                ✉️
              </span>{' '}
              <Strong>Have questions about creating an account?</Strong> Please
              check your email for access from your employer or organization or
              contact us at{' '}
              <EmailLink
                href={`mailto:${supportAddress}`}
                inheritColor
                underline
              >
                {supportAddress}
              </EmailLink>
              .
            </Text>
          </Alert>
        )}
      </Container>
    );
  }
}

const Box = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const Container = styled(FlexCenter)`
  max-width: 408px;
  width: 100%;

  padding: 0 24px 72px;

  flex-shrink: 0;
  flex-direction: column;
`;

const AirBalloonIcon = styled(Icon)`
  margin-bottom: 24px;
`;

const HeaderBox = styled.div`
  margin-bottom: 48px;
`;

const LinkBox = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const Form = styled(FlexRow.withComponent('form'))`
  flex-wrap: wrap;
  width: 100%;
`;

const EmailLink = styled(Link)`
  @media (max-width: ${MobileSize}) {
    word-wrap: break-word;
  }
`;

const mapStateToProps = state => ({
  isLoading: isLoading(state, 'login'),
  loginErrorMessage: getErrorMessage(state, 'login'),
});

export default connect(mapStateToProps, { login, redirect })(Login);
