import Bowser from 'bowser';
import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  withRouter,
} from 'react-router-dom';

import { LoadingFullPage } from '@summer/ui-components';

import {
  DashboardRoute,
  GuideRoute,
  OnboardingRoute,
} from 'components/AppRoute';
import ErrorBoundary from 'components/ErrorBoundary';
import ConnectFsaModal from 'components/common/SyncLoans/ConnectFsaModal';
import Loading from 'components/pages/LoadingPage';
import ADPEmailVerificationLandingPage from 'components/pages/auth/ADPEmailVerificationLandingPage';
import ADPSSOErrorPage from 'components/pages/auth/ADPSSOErrorPage';
import ForgotPassword from 'components/pages/auth/ForgotPassword';
import { LoginRoot as Login } from 'components/pages/auth/Login';
import MfaSms from 'components/pages/auth/MfaSms';
import OAuthCallback from 'components/pages/auth/OAuthCallback';
import OAuthIDPInitiated from 'components/pages/auth/OAuthIDPInitiated';
import { PartnerLogout } from 'components/pages/auth/PartnerLogout';
import ResetPassword from 'components/pages/auth/ResetPassword';
import CollegeSavingsPlanner529Marketplace from 'components/pages/collegeSavingsPlanner/529Marketplace';
import CollegeSavingsPlanner529MarketplaceStatePage from 'components/pages/collegeSavingsPlanner/529Marketplace/statePage';
import CollegeSavingsPlannerCalculator from 'components/pages/collegeSavingsPlanner/calculator';
import CollegeSavingsPlannerDashboard from 'components/pages/collegeSavingsPlanner/dashboard';
import CollegeSavingsPlannerScholarshipDatabase from 'components/pages/collegeSavingsPlanner/scholarshipDatabase';
import CollegeSavingsPlannerScholarshipPage from 'components/pages/collegeSavingsPlanner/scholarshipDatabase/scholarshipPage';
import DefaultWizard from 'components/pages/default/wizard';
import EmergencySavingsDashboard from 'components/pages/emergencySavings/dashboard';
import EmergencySavingsMarketplace from 'components/pages/emergencySavings/dashboard/Marketplace';
import EmergencySavingsPayroll from 'components/pages/emergencySavings/dashboard/Payroll';
import EmergencySavingsWizard from 'components/pages/emergencySavings/wizard';
import EmployerContribution from 'components/pages/employerContribution/dashboard/index';
import EmployerContributionV1 from 'components/pages/employerContributionV1/dashboard/index';
import ForgivenessFinder from 'components/pages/forgivenessFinder';
import ForgivenessProgramPage from 'components/pages/forgivenessFinder/components/ForgivenessProgramPage';
import IdrRepayment from 'components/pages/idr/dashboard';
import EditIdrForm from 'components/pages/idr/other/EditIdrForm';
import IDRWizard from 'components/pages/idr/wizard/layout/IDRWizard';
import MyStudentLoans from 'components/pages/loans/MyStudentLoans';
import MyPlan from 'components/pages/myPlan/dashboard';
import CreateAccount from 'components/pages/onboarding/CreateAccount';
import CreateAccountPartner from 'components/pages/onboarding/CreateAccountPartner';
import LoadingRecommendations from 'components/pages/onboarding/LoadingRecommendations';
import LoanInfo from 'components/pages/onboarding/LoanInfo';
import PartialDataCreateAccount from 'components/pages/onboarding/PartialDataCreateAccount';
import Questions from 'components/pages/onboarding/Questions';
import Verify from 'components/pages/onboarding/Verify';
import Welcome from 'components/pages/onboarding/Welcome';
import Overpayments from 'components/pages/overpayments/dashboard';
import PaywallSuccessIdr from 'components/pages/paywall/idr/PaywallSuccessIdr';
import PaywallSuccessPslf from 'components/pages/paywall/pslf/PaywallSuccessPslf';
import EmployerPage from 'components/pages/pslf/EmployerPage';
import EmployerPageV2 from 'components/pages/pslf/EmployerPageV2';
import PSLFVerificationPage from 'components/pages/pslf/PSLFVerificationPage';
import PslfDashboard from 'components/pages/pslf/dashboard';
import PslfWizard from 'components/pages/pslf/wizard';
import RefinancingDashboard from 'components/pages/refinancing/dashboard';
import ResourceCenter from 'components/pages/resourceCenter/dashboard';
import AccruedInterestGuide from 'components/pages/resourceCenter/guides/AccruedInterest';
import IDRGuide from 'components/pages/resourceCenter/guides/IDR';
import PSLFGuide from 'components/pages/resourceCenter/guides/PSLF';
import RecentGraduatesGuide from 'components/pages/resourceCenter/guides/RecentGraduates';
import RefinancingGuide from 'components/pages/resourceCenter/guides/Refinancing';
import RetirementMatch from 'components/pages/retirementMatch/dashboard';
import RetirementMatchFileUpload from 'components/pages/retirementMatch/dashboard/FileUploadPage';
import { UserSettings } from 'components/pages/settings';
import ScheduleConsultationPage from 'components/pages/support/ScheduleConsultationPage';
import Support from 'components/pages/support/dashboard';
import TuitionReimbursementDashboard from 'components/pages/tuitionReimbursement/dashboard';
import TuitionReimbursementCourseRequestWizard from 'components/pages/tuitionReimbursement/wizard/TuitionReimbursementCourseRequestWizard';
import TuitionReimbursementProofOfCompletionWizard from 'components/pages/tuitionReimbursement/wizard/TuitionReimbursementProofOfCompletionWizard';
import { verify } from 'constants/onboardingSteps';
import { initFeatureFlagsForPartners } from 'constants/partnerConfig';
import WizardDemos from 'hooks/Wizard/Demo';
import { checkUser } from 'redux/actions/auth.actions';
import { fetchTuitionReimbursementPartnerConfig } from 'redux/actions/tuitionReimbursement.actions';
import {
  getIsCollegeSavingsPlannerToolEnabled,
  getIsContributionsEnabled,
  getIsCSP529MarketplaceEnabled,
  getIsCSPScholarshipDatabaseEnabled,
  getIsEmergencySavingsDemoEnabled,
  getIsEmergencySavingsV1Enabled,
  getIsNewCSPStartPageEnabled,
  getIsRetirementMatchDemo,
  getIsRetirementMatchV1Enabled,
  getIsTuitionReimbursementEnabled,
  getIsSaveEnabled,
  getIsV2SLCEnabled,
} from 'redux/selectors/flags.selectors';
import {
  getIdrRecommendation,
  getRefiRecommendation,
} from 'redux/selectors/recEngine.selectors';
import { getTuitionReimbursementServiceType } from 'redux/selectors/tuitionReimbursement.selectors';
import { isLoadingWithInit } from 'redux/selectors/ui.selectors';
import {
  getDefaultDashboardPage,
  getHideSupportChat,
  getUserEmail,
  getUserId,
  getUserPartnerKey,
} from 'redux/selectors/user.selectors';
import { hideChat, showChat } from 'services/chat';
import { setupDatadog } from 'services/datadog';
import { initFeatureFlags } from 'services/featureFlags';
import * as flags from 'services/featureFlags/flags';
import { setupSegment } from 'services/segment';
import { getEducationAssistanceLabel } from 'utils/tuitionReimbursement';

const browser = Bowser.getParser(window.navigator.userAgent);
const isSafari = browser.isBrowser('safari');

const App = ({
  currentUserId,
  userEmail,
  location,
  isAuthLoading,
  userPartnerKey,
  showContributions,
  showCollegeSavingsPlanner,
  showNewCSPStartPage,
  showCSPScholarshipDatabase,
  showCSP529Marketplace,
  showRetirementMatchV1,
  showRetirementMatchDemo,
  showTuitionReimbursement,
  showSummerSave,
  showEmergencySavingsDemo,
  showEmergencySavingsV1,
  showV2SLC,
  defaultDashboardPage,
  hideSupportChat,
  tuitionReimbursementServiceType,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isOauthCallback = location.pathname.includes('/oauth-callback');
  const isErrorPage = location.pathname.includes('/error');

  const showRetirementMatch = showRetirementMatchV1 || showRetirementMatchDemo;

  useEffect(() => {
    if (!isOauthCallback) {
      dispatch(checkUser());
    }
  }, [dispatch, isOauthCallback]);

  useEffect(() => {
    if (showTuitionReimbursement) {
      dispatch(fetchTuitionReimbursementPartnerConfig());
    }
  }, [dispatch, showTuitionReimbursement]);

  useEffect(
    () => (hideSupportChat ? hideChat() : showChat()),
    [hideSupportChat],
  );

  useEffect(() => {
    setupSegment();
    setupDatadog();

    initFeatureFlags(location, userEmail, flags);

    const handleStorageEvent = event => {
      if (event.key === 'persist:@@SUMMER') {
        const newValue = JSON.parse(event.newValue);

        const newCurrentUser = JSON.parse(
          newValue ? newValue.currentUser : null,
        );
        const oldCurrentUserId = currentUserId;

        const newUserIsNil = isNil(newCurrentUser) || isNil(newCurrentUser.id);
        const oldUserIsNil = isNil(oldCurrentUserId);

        /**
         * this tab is logged in but another has logged out
         * or
         * this tab is logged out but another has logged in
         */
        if (newUserIsNil !== oldUserIsNil) {
          document.location.reload();
        }
      }
    };

    if (!isSafari) {
      window.addEventListener('storage', handleStorageEvent);
    }

    return function clearStorageEvent() {
      window.removeEventListener('storage', handleStorageEvent);
    };
    // TODO: Add dependencies to hook instead of empty array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isVerificationPage = window.location.pathname.includes(verify);

  const showLoading = isOauthCallback
    ? false
    : isAuthLoading && !isVerificationPage;

  useEffect(
    function handlePartnerFlow() {
      initFeatureFlagsForPartners(userPartnerKey);
    },
    [userPartnerKey],
  );

  return (
    <ErrorBoundary>
      {showLoading ? (
        <LoadingFullPage />
      ) : (
        <Switch location={history.location}>
          <OnboardingRoute exact path="/login" component={Login} />
          <OnboardingRoute exact path="/forgot" component={ForgotPassword} />
          <OnboardingRoute exact path="/reset" component={ResetPassword} />
          <OnboardingRoute exact path="/mfa/sms" component={MfaSms} />
          <OnboardingRoute
            exact
            path="/onboard/adp-profile"
            component={PartialDataCreateAccount}
          />
          <OnboardingRoute
            exact
            path="/logout/:partner"
            component={PartnerLogout}
          />
          <OnboardingRoute
            exact
            path="/user/adp/verify"
            component={ADPEmailVerificationLandingPage}
          />
          <OnboardingRoute
            exact
            path="/saml/error"
            component={ADPSSOErrorPage}
          />
          <OnboardingRoute
            exact
            path="/oauth-callback/:provider"
            component={OAuthCallback}
          />
          <OnboardingRoute
            exact
            path="/oauth/idp-initiated"
            component={OAuthIDPInitiated}
          />
          <OnboardingRoute
            exact
            path="/onboard/create-account"
            component={CreateAccount}
          />
          <OnboardingRoute exact path="/onboard/verify" component={Verify} />
          <OnboardingRoute exact path="/onboard/welcome" component={Welcome} />
          <OnboardingRoute
            exact
            path="/onboard/questions"
            component={Questions}
          />
          <OnboardingRoute
            exact
            path="/onboard/loan-info"
            component={LoanInfo}
          />
          <Route
            exact
            path="/onboard/rec-loading"
            component={LoadingRecommendations}
          />
          <OnboardingRoute
            exact
            path="/onboard/:partner"
            component={CreateAccountPartner}
          />
          <OnboardingRoute
            path="/onboard/:partner/:borrowerId"
            component={CreateAccountPartner}
          />
          <OnboardingRoute
            exact
            path="/employer-signature"
            component={EmployerPage}
          />
          <OnboardingRoute
            exact
            path="/employer-signature-v2"
            component={EmployerPageV2}
          />
          <OnboardingRoute
            exact
            path="/employer-signature-v2/verification"
            component={PSLFVerificationPage}
          />
          <DashboardRoute
            exact
            path="/guides"
            mobileHeaderTitle="Resource Center"
            component={ResourceCenter}
          />
          <DashboardRoute
            exact
            path="/plan"
            mobileHeaderTitle="My Plan"
            component={MyPlan}
          />
          {showContributions && !showV2SLC && (
            <DashboardRoute
              exact
              path="/employer-contribution"
              mobileHeaderTitle="Student Loan Contribution"
              component={EmployerContributionV1}
            />
          )}
          {showContributions && showV2SLC && (
            <DashboardRoute
              exact
              path="/employer-contribution*"
              mobileHeaderTitle="Student Loan Contribution"
              component={EmployerContribution}
            />
          )}
          <DashboardRoute
            exact
            path="/loans"
            mobileHeaderTitle="My Student Loans"
            component={MyStudentLoans}
          />
          {showSummerSave && (
            <DashboardRoute
              exact
              path="/repayment"
              mobileHeaderTitle="Income-Driven Repayment"
              component={IdrRepayment}
            />
          )}
          <DashboardRoute
            exact
            path="/refinancing"
            mobileHeaderTitle="Refinancing your loans"
            component={RefinancingDashboard}
          />
          <DashboardRoute
            path="/overpayments"
            mobileHeaderTitle="Extra Payments"
            component={Overpayments}
          />
          <DashboardRoute
            path="/support"
            mobileHeaderTitle="Support"
            component={Support}
          />
          <DashboardRoute
            exact
            path="/settings"
            mobileHeaderTitle="Settings"
            component={UserSettings}
          />
          <DashboardRoute
            exact
            path="/forgiveness-finder"
            mobileHeaderTitle="Forgiveness Finder"
            component={ForgivenessFinder}
          />
          <DashboardRoute
            path="/forgiveness-finder/:uid"
            mobileHeaderTitle="Forgiveness Finder"
            component={ForgivenessProgramPage}
          />
          {showCollegeSavingsPlanner && (
            <DashboardRoute
              path="/college-savings-planner/calculator"
              mobileHeaderTitle="College Savings Planner"
              component={CollegeSavingsPlannerCalculator}
            />
          )}
          {showCollegeSavingsPlanner && showCSP529Marketplace && (
            <Route
              path="/college-savings-planner/529-marketplace/:state"
              mobileHeaderTitle="529 Plans"
              component={CollegeSavingsPlanner529MarketplaceStatePage}
            />
          )}
          {showCollegeSavingsPlanner && showCSP529Marketplace && (
            <Route
              path="/college-savings-planner/529-marketplace"
              mobileHeaderTitle="529 Plans"
              component={CollegeSavingsPlanner529Marketplace}
            />
          )}
          {showCollegeSavingsPlanner && showCSPScholarshipDatabase && (
            <Route
              path="/college-savings-planner/scholarship-database/:uid"
              mobileHeaderTitle="College Savings Planner"
              component={CollegeSavingsPlannerScholarshipPage}
            />
          )}
          {showCollegeSavingsPlanner && showCSPScholarshipDatabase && (
            <Route
              path="/college-savings-planner/scholarship-database"
              mobileHeaderTitle="College Savings Planner"
              component={CollegeSavingsPlannerScholarshipDatabase}
            />
          )}
          {showCollegeSavingsPlanner && showNewCSPStartPage && (
            <DashboardRoute
              path="/college-savings-planner"
              mobileHeaderTitle="College Savings Planner"
              component={CollegeSavingsPlannerDashboard}
            />
          )}
          {showRetirementMatch && (
            <DashboardRoute
              path="/retirement-match/dashboard"
              mobileHeaderTitle="Retirement Match"
              component={RetirementMatch}
            />
          )}
          {showRetirementMatch && (
            <DashboardRoute
              path="/retirement-match/upload-documents"
              mobileHeaderTitle="Retirement Match: Upload Documents"
              component={RetirementMatchFileUpload}
            />
          )}
          {showTuitionReimbursement && (
            <DashboardRoute
              exact
              path="/educational-assistance"
              mobileHeaderTitle={getEducationAssistanceLabel(
                tuitionReimbursementServiceType,
              )}
              component={TuitionReimbursementDashboard}
            />
          )}
          {showTuitionReimbursement && (
            <Route
              exact
              path="/educational-assistance/course-request"
              component={TuitionReimbursementCourseRequestWizard}
            />
          )}
          {showTuitionReimbursement && (
            <Route
              exact
              path="/educational-assistance/proof-of-completion"
              component={TuitionReimbursementProofOfCompletionWizard}
            />
          )}
          {(showEmergencySavingsDemo || showEmergencySavingsV1) && (
            <DashboardRoute
              exact
              path="/emergency-savings"
              component={EmergencySavingsDashboard}
            />
          )}
          {(showEmergencySavingsDemo || showEmergencySavingsV1) && (
            <Route
              exact
              path="/emergency-savings/wizard"
              component={EmergencySavingsWizard}
            />
          )}
          {showEmergencySavingsDemo && (
            <Route
              exact
              path="/emergency-savings/payroll"
              component={EmergencySavingsPayroll}
            />
          )}
          {showEmergencySavingsDemo && (
            <DashboardRoute
              exact
              path="/emergency-savings/marketplace"
              component={EmergencySavingsMarketplace}
            />
          )}
          <GuideRoute
            exact
            path="/guides/recent-graduates"
            title="Recent Graduates Guide"
            component={RecentGraduatesGuide}
          />
          <GuideRoute
            exact
            path="/guides/idr"
            title="Income-Driven Repayment (IDR) Plan Guide"
            component={IDRGuide}
          />
          <GuideRoute
            exact
            path="/guides/pslf"
            title="Public Service Loan Forgiveness (PSLF) Guide"
            component={PSLFGuide}
          />
          <GuideRoute
            exact
            path="/guides/refinancing"
            title="Refinancing Guide"
            component={RefinancingGuide}
          />
          <GuideRoute
            exact
            path="/guides/accrued-interest"
            title="Accrued Interest Guide"
            component={AccruedInterestGuide}
          />
          {showSummerSave && (
            <Route path="/idr" render={props => <IDRWizard {...props} />} />
          )}
          {showSummerSave && (
            <Route path="/edit-idr-form" component={EditIdrForm} />
          )}

          {showSummerSave && <Route path="/pslf/form" component={PslfWizard} />}
          <Route path="/wizard" component={WizardDemos} />
          <Route
            path="/schedule-consultation"
            component={ScheduleConsultationPage}
          />
          {showSummerSave && (
            <DashboardRoute
              path="/pslf"
              mobileHeaderTitle="Public Service Loan Forgiveness"
              component={PslfDashboard}
            />
          )}
          <Route exact path="/default" component={DefaultWizard} />
          <Route exact path="/loading" component={Loading} />
          {showSummerSave && (
            <Route path="/paywall/success/idr" component={PaywallSuccessIdr} />
          )}

          {showSummerSave && (
            <Route
              path="/paywall/success/pslf"
              component={PaywallSuccessPslf}
            />
          )}
          {!isErrorPage && (
            <Redirect
              to={{ pathname: defaultDashboardPage, state: location?.state }}
            />
          )}
        </Switch>
      )}
      <ConnectFsaModal />
    </ErrorBoundary>
  );
};

const mapStateToProps = state => ({
  isIdrRecommended: getIdrRecommendation(state),
  isAnyRefiRecommended: getRefiRecommendation(state),
  currentUserId: getUserId(state),
  userEmail: getUserEmail(state),
  isAuthLoading: isLoadingWithInit(state, 'checkUser'),
  userPartnerKey: getUserPartnerKey(state),
  showContributions: getIsContributionsEnabled(state),
  showV2SLC: getIsV2SLCEnabled(state),
  showCollegeSavingsPlanner: getIsCollegeSavingsPlannerToolEnabled(state),
  showNewCSPStartPage: getIsNewCSPStartPageEnabled(state),
  showCSPScholarshipDatabase: getIsCSPScholarshipDatabaseEnabled(state),
  showCSP529Marketplace: getIsCSP529MarketplaceEnabled(state),
  showRetirementMatchV1: getIsRetirementMatchV1Enabled(state),
  showRetirementMatchDemo: getIsRetirementMatchDemo(state),
  showTuitionReimbursement: getIsTuitionReimbursementEnabled(state),
  showSummerSave: getIsSaveEnabled(state),
  showEmergencySavingsDemo: getIsEmergencySavingsDemoEnabled(state),
  showEmergencySavingsV1: getIsEmergencySavingsV1Enabled(state),
  defaultDashboardPage: getDefaultDashboardPage(state),
  hideSupportChat: getHideSupportChat(state),
  tuitionReimbursementServiceType: getTuitionReimbursementServiceType(state),
});

export default connect(mapStateToProps, { checkUser })(withRouter(App));
