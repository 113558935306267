export const COLORS = {
  azure: '#0076D1',
  lightBlue: '#00a6ff',
  jade: '#2ddeab',
  darkGreen: '#0e8273',
  darkGrey: '#6d7581',
  darkerGrey: '#3f4956',
  blueBlack: '#212934',
  blueGrey: '#e0ebf1',
  medDarkGrey: '#c8d0da',
  medGrey: '#8f98a5',
  grey: '#dfe3e8',
  lightestGrey: '#f9fafc',
  lighterGrey: '#f2f4f7',
  white: '#ffffff',
  yellow: '#feff80',
  orange: '#ff6c45',
  magenta: '#eb7dd8',
  magentaDark: '#d659c1',
  gold: '#ffda3f',
  goldDark: '#f7c400',
  cobalt: '#2678ff',
  cobaltDark: '#0351d2',
  skyBlue: '#69c2ff',
  skyBlueDark: '#0097ff',
  ceruleanBlue: '#0060AA',
  covidGreen: '#1ca383',
  violet: '#be64ec',
  violetDark: '#9e36d1',
  raven: '#6d7581',
  ravenDark: '#3f4956',
  sand: '#d8af69',
  sandDark: '#d09f00',
  pink: '#fef2ee',
  lightPink: '#f8e7f4',
  darkPink: '#6D0356',
};

export const textColors = {
  default: COLORS.darkerGrey,
  secondary: COLORS.medGrey,
  summer: COLORS.azure,
  error: COLORS.orange,
  light: COLORS.white,
  dark: COLORS.blueBlack,
};

export const MobileSize = '600px';
export const TabletSize = '768px';
export const SmallDesktopSize = '1024px';
export const DesktopHDSize = '1440px';

export const SidebarWidth = `224px`;
export const CollapseSidebarSize = SmallDesktopSize;

// guid layout
export const SCROLL_OFFSET = 88;
