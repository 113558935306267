import { Formik } from 'formik';
import { get, toUpper, isNil } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  FlexColumn,
  FlexRow,
  Header,
  Text,
  Button,
  CheckSmall,
  Icon,
  Testimonial,
  COLORS,
} from '@summer/ui-components';

import Platform, { MOBILE } from 'components/hoc/Platform';
import CreateAccountForm from 'components/pages/onboarding/CreateAccountForm';
import Marketing from 'components/pages/onboarding/marketing';
import { Disclaimer } from 'components/pages/onboarding/shared';
import OAuthButtons from 'components/shared/OAuthButtons';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { verify } from 'constants/onboardingSteps';
import { partnerConfig } from 'constants/partnerConfig';
import { TabletSize, MobileSize } from 'constants/styleguide';
import { resetState } from 'redux/actions/resetAction.actions';
import { createAccount } from 'redux/actions/user.actions';
import { getOnboardingBadEmail } from 'redux/selectors/onboarding.selectors';
import { isLoading } from 'redux/selectors/ui.selectors';
import { createAccountValidationSchema2 } from 'schemas/onboard';
import { hexToRgba } from 'utils/common';
import { scrollToError } from 'utils/formik';

const defaultInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  partnerCode: '',
  partnerGuid: '',
  termsOfService: false,
};

const mappedPartnerKey = {
  aftconvention: 'aftbenefit',
  prnpt: 'prn',
};

const CreateAccountPartner = ({
  match,
  isLoading,
  windowWidth,
  platformType,
}) => {
  const partnerConfigs = useMemo(() => {
    return partnerConfig[match.params.partner];
  }, [match]);
  const dispatch = useDispatch();
  const headerRef = useRef(null);
  const [backgroundHeight, setBackgroundHeight] = useState();

  const matchedPartner =
    mappedPartnerKey[match.params.partner] || match.params.partner;

  const partner = toUpper(matchedPartner);
  const { borrowerId } = match.params;

  const isMobile = platformType === MOBILE;

  useEffect(
    function updateBackgroundHeight() {
      setBackgroundHeight(headerRef.current?.offsetTop);
    },
    [windowWidth],
  );

  useEffect(
    function handleMissingPartnerAndResetState() {
      if (isNil(partnerConfigs)) {
        dispatch(resetState());
      }
    },
    [dispatch, partnerConfigs],
  );

  if (isNil(partnerConfigs)) {
    window.location.href = '/';
    return null;
  }

  if (partnerConfigs.redirectTo) {
    window.location.href = partnerConfigs.redirectTo;
    return null;
  }

  const {
    introParagraph,
    outroParagraph,
    outroFinePrintParagraph,
    emailHelpMessage,
    benefitsHeaderText,
    usePartnerCode,
    usePartnerGuid,
    partnerCodeOptional,
    hideLogoCopy,
    hasLogo,
    logoSrc,
    logoWidth,
    hasSlc,
    isRecordKeeper,
    title,
    testimonial,
    customQuote,
    infoItems,
    useCustomCode,
    customCodeName,
    customCodeLabel,
    customCodeHelpText,
    hideMarketingSection,
    partnerGuidHelpText,
  } = partnerConfigs;
  const urlPartnerData = partnerConfigs.hasPartnerIdentifierData
    ? partnerConfigs.urlPartnerData()
    : null;

  const getHelpText = () => {
    return (
      <Text paragraph small color="secondary" center>
        Having trouble? Contact us at{' '}
        <Link href={`mailto:${supportAddress}`} inheritColor underline>
          {supportAddress}
        </Link>{' '}
        and we’ll get back to you.
      </Text>
    );
  };

  const showTestimonial = customQuote => {
    switch (testimonial) {
      case 'corry':
        return (
          <Testimonial
            image="/images/testimonials/corry.png"
            quote={
              customQuote ??
              '\u201CMy situation is frustratingly complex with many types of loans. It\u0027s been remarkable to have Summer in my corner helping me with a customized plan, saving me nearly $20,000 this year! I couldn\u0027t have done this alone.\u201D'
            }
            name="&mdash; Corry P."
          />
        );
      case 'shante':
        return (
          <Testimonial
            image="/images/testimonials/shante.png"
            quote="&ldquo;Summer answered all of my questions right then and there. They told me about the pros and cons of loan options and specifically what plan I qualify for. They were extremely clear in their messaging and it didn&rsquo;t feel like a sales pitch.&rdquo;"
            name="&mdash; Shante H., Oregon"
          />
        );
      case 'jackalyne':
        return (
          <Testimonial
            image="/images/testimonials/jackalyne.png"
            quote="&ldquo;I truly believe I&rsquo;d still be paying my student loans back if it weren&rsquo;t for Summer! They were with me the entire process, they had answers when others didn&rsquo;t, and they were always quick and friendly with every inquiry I had no matter how small or complicated. $13,000 &lsquo;richer&rsquo; thanks to your team!&rdquo;"
            name="&mdash; Jackalyne, New York"
          />
        );
      case 'jennifer':
      default:
        return (
          <Testimonial
            image="/images/testimonials/jennifer-cleaveland.png"
            quote="&ldquo;Summer was great at explaining everything in simple terms and helping me understand what I needed to do.&rdquo;"
            name="&mdash; Jennifer C., Ohio"
          />
        );
    }
  };

  const InfoItem = ({ children }) => (
    <InfoItemContainer>
      <Check width={16} stroke={COLORS.jade} SvgComponent={CheckSmall} />
      <Text large color="dark" paragraph>
        {children}
      </Text>
    </InfoItemContainer>
  );

  // This needs to be configured per partner
  const getInfoItemsList = () => {
    if (infoItems) {
      return infoItems;
    }

    return [
      'Track your loans',
      'Compare repayment plans',
      'Enroll in forgiveness programs',
      'Access guides and resources',
      'Get advice from student loan experts',
    ];
  };

  const handleSubmit = values => {
    const { firstName, lastName, email, password, partnerCode, partnerGuid } =
      values;
    if (window.fbq) {
      window.fbq('track', 'CompleteRegistration');
    }

    let partnerIdentifierData;

    if (partnerConfigs.hasPartnerIdentifierData) {
      partnerIdentifierData = partnerConfigs.getPartnerIdentifierData(values);
    }

    dispatch(
      createAccount({
        firstName,
        lastName,
        email,
        password,
        currentStep: verify,
        partner: matchedPartner,
        partnerIdentifierData,
        partnerCode,
        usePartnerCode: partnerCodeOptional ? !!partnerCode : usePartnerCode,
        partnerGuid,
        usePartnerGuid,
      }),
    );
  };

  const renderForm = formik => {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      isValid,
    } = formik;

    const showHeader = !(hasSlc || isRecordKeeper);

    return (
      <Form onSubmit={handleSubmit}>
        {showHeader && (
          <FormHeader>
            <Header as="h2">Create an account</Header>
          </FormHeader>
        )}
        {partnerConfigs.useOauth && (
          <OAuthButtons
            type="create"
            partner={partner}
            partnerCode={values.partnerCode}
            usePartnerCode={
              partnerCodeOptional ? !!values.partnerCode : usePartnerCode
            }
            usePartnerGuid={usePartnerGuid}
            partnerGuid={values.partnerGuid}
            identifierValue={values[customCodeName]}
            borrowerId={borrowerId}
            oauthProviders={
              partnerConfigs.oauthProviders ?? ['google', 'facebook']
            }
          />
        )}
        {!partnerConfigs.hideAccountForm && (
          <>
            <CreateAccountForm
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              isSubmitting={isSubmitting}
              handleBlur={handleBlur}
              emailHelpMessage={emailHelpMessage}
              partner={get(match, 'params.partner')}
              title={title}
              hasSlc={hasSlc}
              isRecordKeeper={isRecordKeeper}
              partnerData={urlPartnerData}
              usePartnerCode={usePartnerCode}
              usePartnerGuid={usePartnerGuid}
              partnerGuidHelpText={partnerGuidHelpText}
              useCustomCode={useCustomCode}
              customCodeName={customCodeName}
              customCodeLabel={customCodeLabel}
              customCodeHelpText={customCodeHelpText}
            />
            <ButtonContainer>
              <Button
                fakeDisabled={!isValid}
                isLoading={isLoading}
                type="submit"
                onClick={() => scrollToError(errors)}
              >
                Create my free account
              </Button>
            </ButtonContainer>
          </>
        )}
        <ButtonContainer>
          <Link to="/login">Already have a Summer account? Sign in</Link>
        </ButtonContainer>
      </Form>
    );
  };

  const resolvedLogoSrc = typeof logoSrc === 'function' ? logoSrc() : logoSrc;

  return (
    <>
      <Container>
        <Background backgroundHeight={backgroundHeight} />
        {hasLogo && (
          <LogoContainer>
            {hideLogoCopy || !resolvedLogoSrc ? null : (
              <LogoText>
                <Text large bold>
                  in partnership with
                </Text>
              </LogoText>
            )}
            <Logo width={logoWidth} src={resolvedLogoSrc} />
          </LogoContainer>
        )}
        <Info isMobile={isMobile}>
          <InfoHeader>
            <Header>{partnerConfigs.headerText}</Header>
            <div ref={headerRef} />
          </InfoHeader>
          <Text large color="dark" bold paragraph>
            {typeof introParagraph === 'string'
              ? introParagraph
              : introParagraph()}
          </Text>
          <Spacer />
          <Text large color="dark" bold paragraph>
            {benefitsHeaderText}
          </Text>
          <InfoItems>
            {getInfoItemsList().map((item, idx) => (
              <InfoItem key={idx}>{item}</InfoItem>
            ))}
          </InfoItems>
          {!isNil(outroParagraph) && (
            <Text large paragraph>
              {outroParagraph}
            </Text>
          )}
          {!isNil(outroFinePrintParagraph) && (
            <Text paragraph small color="secondary">
              {outroFinePrintParagraph}
            </Text>
          )}
          {testimonial && showTestimonial(customQuote)}
        </Info>
        <FormAndHelp>
          <FormContainer>
            {partnerConfigs.hasPartnerIdentifierData &&
            !urlPartnerData.isValidUrl ? (
              partnerConfigs.errorPage
            ) : (
              <Formik
                initialValues={
                  partnerConfigs.getInitialValues
                    ? partnerConfigs.getInitialValues()
                    : defaultInitialValues
                }
                validationSchema={createAccountValidationSchema2(
                  partnerConfigs,
                )}
                onSubmit={handleSubmit}
              >
                {renderForm}
              </Formik>
            )}
            <Help>{getHelpText()}</Help>
          </FormContainer>
          <DisclaimerContainer>
            <Disclaimer />
          </DisclaimerContainer>
        </FormAndHelp>
      </Container>
      {!hideMarketingSection && <Marketing />}
    </>
  );
};

const Container = styled(FlexRow)`
  margin-top: 76px;
  padding: 0 24px 24px;
  width: 100%;
  justify-content: center;
  flex: 1 0 auto;

  @media (max-width: ${TabletSize}) {
    margin-top: 54px;
    flex-direction: column;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(${props => props.backgroundHeight}px + 30px);
  background-image: linear-gradient(
    to bottom,
    rgba(252, 254, 255, 0.53),
    ${hexToRgba(COLORS.azure, '0.05')}
  );
  z-index: -1;

  transition: height 1s;

  @media (max-width: ${TabletSize}) {
    height: calc(${props => props.backgroundHeight}px + 24px);
  }
`;

const LogoContainer = styled(FlexRow)`
  position: absolute;
  top: 8px;
  right: 30px;
  align-items: center;
  height: 80px;

  @media (max-width: ${TabletSize}) {
    right: 24px;
  }

  @media (max-width: ${MobileSize}) {
    right: 24px;
  }
`;

const LogoText = styled.div`
  @media (max-width: ${TabletSize}) {
    display: none;
  }
`;

const Logo = styled.img`
  margin-left: 20px;
  width: ${({ width }) => width}px;

  @media (max-width: ${TabletSize}) {
    width: ${({ width }) => width - 30}px;
  }

  @media (max-width: ${MobileSize}) {
    ${({ width }) => (width > 200 ? `width: ${width - 125}px;` : '')}
  }
`;

const Info = styled.div`
  max-width: 390px;
  width: 100%;
  margin-right: 88px;
  margin-left: auto;

  flex: 0 1 auto;

  @media (max-width: ${TabletSize}) {
    flex: 1 0 auto;
    max-width: 560px;
    margin: 0 auto;

    ${({ isMobile }) =>
      !isMobile &&
      `border-bottom: solid 1px ${COLORS.grey};
    `}

    margin-bottom: 32px;
  }
`;

const Spacer = styled.div`
  height: 24px;
`;

const FormAndHelp = styled.div`
  max-width: 560px;
  width: 100%;
  margin-right: auto;
  flex: 0 1 auto;

  @media (max-width: ${TabletSize}) {
    flex: 1 0 auto;
    margin-left: auto;
  }
`;

const Help = styled.div`
  margin-top: 16px;
`;

const DisclaimerContainer = styled.div`
  margin: 16px 24px;
`;

const InfoHeader = styled.div`
  margin-bottom: 60px;

  @media (max-width: ${TabletSize}) {
    margin-bottom: 40px;
  }

  & > h1 {
    font-size: 40px;

    @media (max-width: ${TabletSize}) {
      font-size: 32px;
    }
  }
`;

const Form = styled.form``;

const FormHeader = styled.div`
  margin-bottom: 32px;
`;

const FormContainer = styled.div`
  max-width: 560px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 ${COLORS.medDarkGrey};
  padding: 48px 64px;
  background-color: ${COLORS.white};

  @media (max-width: ${TabletSize}) {
    box-shadow: inherit;
    padding: 0;
  }
`;

const InfoItems = styled(FlexColumn)`
  margin-top: 10px;

  @media (max-width: ${TabletSize}) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const InfoItemContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;
`;

const Check = styled(Icon)`
  margin-right: 16px;
  line-height: 32px;
  height: 16px;
  max-width: 16px;
  width: 16px;
  display: inline;
  flex: 1 0 auto;
`;

const ButtonContainer = styled.div`
  text-align: center;
  & > * {
    margin-bottom: 16px;

    width: 100%;
  }
`;

const mapStateToProps = state => ({
  badEmail: getOnboardingBadEmail(state),
  isLoading: isLoading(state, 'createAccount'),
});

export default withRouter(
  connect(mapStateToProps, { createAccount })(Platform(CreateAccountPartner)),
);
