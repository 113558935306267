import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { Container, ResponsiveColumn, Row } from '@summer/ui-components';

import IdrStartViewCard from 'components/pages/idr/dashboard/startView/IdrStartViewCard';
import {
  IdrStartViewFaq,
  IdrStartViewMessage,
} from 'components/pages/idr/dashboard/startView/IdrStartViewFooter';
import PrivacyPolicyLink from 'components/shared/PrivacyPolicyLink';
import {
  getCurrentIdrFormStep,
  showConfirmIdrPlan,
} from 'redux/selectors/idr.selectors';
import {
  getEstIdrMonthlyPayment,
  getIdrRecommendation,
} from 'redux/selectors/recEngine.selectors';
import { getIdrProgress } from 'utils/idr';

const IdrStartView = ({
  estIdrMonthlyPayment,
  idrCurrentStep,
  showConfirmIdrPlan,
  recommended,
}) => {
  return (
    <GridContainer>
      <Row center>
        <ResponsiveColumn desktopCol={8} mobileCol={12} tabletCol={12}>
          <IdrStartViewCard
            estIdrMonthlyPayment={estIdrMonthlyPayment}
            idrProgress={getIdrProgress(idrCurrentStep)}
            showConfirmIdrPlan={showConfirmIdrPlan}
          />
        </ResponsiveColumn>
      </Row>

      <FooterRow center>
        <ResponsiveColumn desktopCol={3} mobileCol={12} tabletCol={12}>
          <IdrStartViewMessage />
        </ResponsiveColumn>

        <ResponsiveColumn desktopCol={5} mobileCol={12} tabletCol={12}>
          <IdrStartViewFaq isIdrRecommended={recommended} />
          <PrivacyPolicyLinkIDRPageStartView />
        </ResponsiveColumn>
      </FooterRow>
    </GridContainer>
  );
};

const GridContainer = styled(Container)`
  margin-top: -48px;
`;

const FooterRow = styled(Row)`
  margin-top: 32px;
  margin-bottom: 32px;
`;

const PrivacyPolicyLinkIDRPageStartView = styled(PrivacyPolicyLink)`
  justify-content: flex-start;
  text-align: left;
  padding: 30px 0px 35px;
`;

const mapStateToProps = state => ({
  recommended: getIdrRecommendation(state),
  estIdrMonthlyPayment: getEstIdrMonthlyPayment(state),
  idrCurrentStep: getCurrentIdrFormStep(state),
  showConfirmIdrPlan: showConfirmIdrPlan(state),
});

export default connect(mapStateToProps)(IdrStartView);
