import React from 'react';
import styled from 'styled-components';

import { Alert, Button, Text } from '@summer/ui-components/src';

import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { addWbrToLongEmail } from 'utils/email';

import { TextContainer } from '../default/wizard/layout/layout';
import { VerificationPageHeader } from '../onboarding/shared/VerificationPageHeader';

/**
 * PreOtpPage component
 * @param {object} props
 * @param {string} props.pageTitle
 * @param {string} props.email
 * @param {string} props.error
 * @param {function} props.onSubmitCode
 * @param {function} props.onSendCode
 * @param {function} props.secondsBetweenCodes
 * @returns {JSX.Element}
 */
export const PreOtpPage = ({ pageTitle, email, error, onSendCode }) => {
  /**
   * Sends OTP notification to user
   * @returns {void}
   */
  const sendOtpNotification = () => {
    onSendCode();
  };

  return (
    <PageContainer>
      <ContentWrapper>
        <VerificationPageHeader verificationPageText={pageTitle} />
        <TextContainer>
          <Text large center paragraph>
            We&rsquo;ll send a code to your email address{' '}
            {addWbrToLongEmail(email)}
          </Text>
        </TextContainer>
        <Button type="submit" fullWidth onClick={sendOtpNotification}>
          Send code
        </Button>

        {error && (
          <Alert theme="warning" testId="invalid-code-alert-text">
            {error}
          </Alert>
        )}
        <Text small paragraph align="center">
          Having trouble? Contact us at{' '}
          <Link href={`mailto:${supportAddress}`} inheritColor underline>
            {supportAddress}
          </Link>{' '}
          and we&rsquo;ll get back to you.
        </Text>
      </ContentWrapper>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 24px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;
