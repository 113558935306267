import React from 'react';
import styled from 'styled-components';

import { Text, FlexColumn, COLORS } from '@summer/ui-components/src';

import GlobalError from 'components/shared/GlobalError';
import OAuthButton from 'components/shared/OAuthButton';
import { hexToRgba } from 'utils/common';

const OAuthBox = ({ provider, oidcProvider }) => {
  return (
    <>
      <Container data-testId={`${provider}-oauth-box`}>
        <SingleSignOnLabel paragraph>
          Continue with single sign-on (SSO):
        </SingleSignOnLabel>
        <OAuthButton
          type="login"
          provider={provider}
          oidcName={oidcProvider?.name}
          oidcDisplayName={oidcProvider?.displayName}
          oidcLogoSrc={oidcProvider?.logoSrc}
        />
      </Container>
      <GlobalError label="oauth" />
    </>
  );
};

const Container = styled(FlexColumn)`
  width: 100%;
  padding: 16px;
  background-color: ${hexToRgba(COLORS.azure, '0.06')};
`;

const SingleSignOnLabel = styled(Text)`
  margin-bottom: 12px;
`;

export default OAuthBox;
