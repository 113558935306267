import React from 'react';

import LoginForm from 'components/pages/auth/Login/LoginForm';
import UniversalWelcome from 'components/pages/auth/Login/UniversalWelcome';
import { useFeatureFlag } from 'hooks/launchDarkly';
import { FeatureFlagsEnum } from 'utils/featureFlags.enum';

/**
 * Login component. This component will render the new login page if the feature flag is enabled.
 * @return {ReactElement}
 */
export const LoginRoot = ({ ...props }) => {
  const useNewLoginPage = useFeatureFlag(FeatureFlagsEnum.UseNewLoginPage);
  const showCheggLoginButton = useFeatureFlag(
    FeatureFlagsEnum.ShowCheggLoginButton,
  );
  return useNewLoginPage ? (
    <UniversalWelcome />
  ) : (
    <LoginForm showCheggLoginButton={showCheggLoginButton} {...props} />
  );
};

export default LoginRoot;
