import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import {
  Text,
  Header,
  FlexRow,
  FlexColumn,
  Icon,
  COLORS,
  Strong,
  MinusSignOutline,
  PlusSignOutline,
  Collapse,
} from '@summer/ui-components';

/**
 * A collapsible insights component that displays highlights and important points to keep in mind
 * @param {Object} props
 * @param {Array<string>} props.highlights - Array of highlight text items to display
 * @param {Array<string>} props.keepInMind - Array of "keep in mind" text items to display
 * @param {boolean} [props.defaultOpen=true] - Whether the accordion should be open by default
 * @returns {React.ReactElement} Insights component with collapsible sections for highlights and reminders
 */

const Insights = ({ highlights, keepInMind, defaultOpen = true }) => {
  return (
    <InsightsContainer>
      <Collapse
        openByDefault={defaultOpen}
        trigger={({ openState }) => (
          <TriggerContainer>
            <ControlContainer>
              <CircleIcon
                width={16}
                height={16}
                fill={COLORS.azure}
                SvgComponent={openState ? MinusSignOutline : PlusSignOutline}
              />
              <HideInsights>
                {openState ? 'Hide' : 'Show'} Insights
              </HideInsights>
            </ControlContainer>
          </TriggerContainer>
        )}
      >
        <ContentContainer data-testid="insights-content-container">
          <SectionsContainer>
            {highlights.length > 0 && (
              <Section>
                <SectionHeader>
                  <StarIcon>⭐</StarIcon>
                  <Header h3>Highlights</Header>
                </SectionHeader>
                <BulletList>
                  {highlights.map((highlight, index) => (
                    <BulletItem key={`highlight-${index}`}>
                      <Text paragraph>{highlight}</Text>
                    </BulletItem>
                  ))}
                </BulletList>
              </Section>
            )}

            {keepInMind.length > 0 && (
              <Section>
                <SectionHeader>
                  <LightbulbIcon>💡</LightbulbIcon>
                  <Header h3>Keep in mind</Header>
                </SectionHeader>
                <BulletList>
                  {keepInMind.map((item, index) => (
                    <BulletItem key={`keep-in-mind-${index}`}>
                      <Text paragraph>{item}</Text>
                    </BulletItem>
                  ))}
                </BulletList>
              </Section>
            )}
          </SectionsContainer>
        </ContentContainer>
      </Collapse>
    </InsightsContainer>
  );
};

Insights.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
  keepInMind: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultOpen: PropTypes.bool,
};

const InsightsContainer = styled.div`
  padding: 0 24px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  margin-bottom: 24px;
  background-color: ${COLORS.lightestGrey};
  box-shadow: 0 2px 10px 0 ${COLORS.grey};
`;

const TriggerContainer = styled(FlexRow)`
  width: 100%;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid ${COLORS.grey};
`;

const ControlContainer = styled(FlexRow)`
  align-items: center;
`;

const CircleIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`;

const ContentContainer = styled.div`
  padding: 24px 0;
`;

const SectionsContainer = styled(FlexRow)`
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
`;

const Section = styled(FlexColumn)`
  flex: 1;
  min-width: 300px;
`;

const SectionHeader = styled(FlexRow)`
  align-items: center;
  margin-bottom: 16px;
`;

const StarIcon = styled.span`
  font-size: 20px;
  margin-right: 8px;
`;

const LightbulbIcon = styled.span`
  font-size: 20px;
  margin-right: 8px;
`;

const BulletList = styled.ul`
  padding-left: 20px;
  margin: 0;
`;

const BulletItem = styled.li`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const HideInsights = styled(Strong)`
  color: ${COLORS.azure};
`;

export default Insights;
