import { push as redirectTo } from 'connected-react-router';

import {
  NETWORK_SUCCESS,
  SEND_PSLF_OTP_CODE_EMAIL,
  VERIFY_PSLF_OTP_CODE,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import handleSideEffects from 'redux/handleSideEffects';

export const submitPSLFOTPCodeLabel = 'submitPSLFOTPCode';
export const sendOTPNotificationCodeLabel = 'sendPSLFOTPCodeEmail';

const verifyPSLFOtpMiddleware = handleSideEffects({
  [SEND_PSLF_OTP_CODE_EMAIL]: (dispatch, { payload: { token }, meta }) => {
    dispatch(
      apiRequest({
        url: '/v1/employer-email/verification/send',
        method: 'POST',
        fromAction: SEND_PSLF_OTP_CODE_EMAIL,
        label: sendOTPNotificationCodeLabel,
        headers: {
          Authorization: token,
        },
        meta,
      }),
    );
  },

  [VERIFY_PSLF_OTP_CODE]: (dispatch, { payload: { code, token } }) => {
    const endpoint = '/v1/employer-email/verification/verify';
    const method = 'POST';

    dispatch(
      apiRequest({
        url: endpoint,
        method,
        fromAction: VERIFY_PSLF_OTP_CODE,
        label: submitPSLFOTPCodeLabel,
        body: { code },
        headers: {
          Authorization: token,
        },
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { meta, payload }) => {
    if (meta.fromAction === SEND_PSLF_OTP_CODE_EMAIL) {
      if (meta.onSuccess) {
        meta.onSuccess();
      }
    }

    if (meta.fromAction === VERIFY_PSLF_OTP_CODE) {
      dispatch(
        redirectTo({
          pathname: `/employer-signature-v2`,
          search: `?token=${payload.response.token}`,
          state: { token: payload.response.token },
        }),
      );
    }
  },
});

export default verifyPSLFOtpMiddleware;
