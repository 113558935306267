import React from 'react';
import styled from 'styled-components';

import { FlexColumn, Header, Text } from '@summer/ui-components';

import Pslf from 'components/pages/idr/wizard/steps/ReviewYourPlan/NewRYP/Pslf';
import { TabletSize, MobileSize } from 'constants/styleguide';

const RypHeader = ({ pslf }) => (
  <HeaderContainer>
    <HeaderText>
      <Header h2>Let&rsquo;s find the right plan for you</Header>
      <Text paragraph>
        Once you select your plan, Summer will help you complete and send your
        application in just a few steps.
      </Text>
    </HeaderText>
    <PslfContainer>
      <Pslf pslf={pslf} />
    </PslfContainer>
  </HeaderContainer>
);

const HeaderContainer = styled.div`
  max-width: 1024px;
  margin: auto;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${TabletSize}) {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

const HeaderText = styled(FlexColumn)`
  max-width: 540px;
  margin-right: 16px;

  & > h2 {
    margin-bottom: 8px;
  }

  @media (max-width: ${TabletSize}) {
    margin-right: 0;

    & > div {
      margin-bottom: 16px;
    }
  }
`;

const PslfContainer = styled.div`
  max-width: 304px;
  min-width: 240px;
  max-height: 80px;

  @media (max-width: ${MobileSize}) {
    text-align: left;
    max-width: unset;
    max-height: unset;
    width: 100%;
  }
`;

export default RypHeader;
