import React from 'react';
import styled from 'styled-components';

import { FlexColumn, Header, Icon, Text } from '@summer/ui-components/src';

import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';

import { ReactComponent as SessionTimeoutIcon } from './assets/session-timeout-icon.svg';

const SessionTimeoutInnerText = styled(Text)`
  margin-top: 8px;
  margin-bottom: 32px;
`;

const SessionTimeoutContainer = styled(FlexColumn)`
  border: 1px solid var(--Colors-grey, #dfe3e8);
  border-radius: 8px;
  padding: 24px;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 32px;
  background-color: #f9fafc;
`;

export const SessionTimeout = () => {
  return (
    <SessionTimeoutContainer>
      <Icon SvgComponent={SessionTimeoutIcon} />
      <Header align="center" as="h1">
        Session Timed Out
      </Header>
      <SessionTimeoutInnerText paragraph align="center">
        Your session has expired. To continue, please return to the original
        email you used to access this platform and click the direct link again.
      </SessionTimeoutInnerText>

      <SessionTimeoutInnerText align="center">
        Having trouble? Contact us at{' '}
        <Link href={`mailto:${supportAddress}`} inheritColor underline>
          {supportAddress}
        </Link>{' '}
        and we’ll get back to you.
      </SessionTimeoutInnerText>
    </SessionTimeoutContainer>
  );
};
