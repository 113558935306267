import { format, parseISO } from 'date-fns';
import _, { find, get, map } from 'lodash';
import fp from 'lodash/fp';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import {
  FormStatus,
  FormTypes,
} from '@simplifidev/shared/dist/constants/forms';

import {
  Button,
  COLORS,
  FlexRow,
  LoadingBars,
  Text,
} from '@summer/ui-components';

import HellosignAlert from 'components/common/HellosignAlert';
import SignRequestRow from 'components/common/SignRequestRow';
import Platform, { MOBILE, TABLET } from 'components/hoc/Platform';
import PslfCompleteModal from 'components/pages/pslf/dashboard/myPslfCertifications/CompletePage/modals/PslfCompleteModal';
import { Link } from 'components/shared/typography';
import { MobileSize } from 'constants/styleguide';
import { useAlertHellosignError, useHellosign } from 'hooks/hellosign';
import { trackServerSide } from 'redux/actions/analytics.actions';
import {
  clearCurrentPslfForm,
  fetchCurrentPslfForm,
  setCurrentPslfForm,
} from 'redux/actions/pslf.actions';
import {
  fetchSignatureRequestListV3,
  updateSignatureRequest,
} from 'redux/actions/signatureRequests.actions';
import { getUserPslfForms } from 'redux/selectors/pslf.selectors';
import { getPslfSignRequests } from 'redux/selectors/signatureRequests.selectors';
import { isLoadingWithInit } from 'redux/selectors/ui.selectors';
import { track } from 'utils/track';

import CertificationAlert from './CertificationAlert';
import PartTimeAlert from './alerts/PartTimeAlert';
import useOpenOnNavigate from './useOpenOnNavigate';

const CompletePage = ({
  pslfForms,
  fetchCurrentPslfForm,
  clearCurrentPslfForm,
  fetchSignatureRequestListV3,
  signatureRequestsList,
  platformType,
  updateSignatureRequest,
  isSignRequestsLoading,
  setCurrentPslfForm,
  serverError,
  trackServerSide,
}) => {
  const [isPslfCompleteModalOpen, setIsPslfCompleteModalOpen] = useState(false);
  const togglePslfCompleteModal = useCallback(
    () => setIsPslfCompleteModalOpen(!isPslfCompleteModalOpen),
    [isPslfCompleteModalOpen],
  );

  const formsReadyForSignature = useMemo(
    () =>
      _.flow(
        fp.filter(
          pslfForm =>
            pslfForm.status === FormStatus.COMPLETED ||
            pslfForm.status === FormStatus.SIGNED ||
            pslfForm.status === FormStatus.PROCESSED,
        ),
        fp.map(pslfForm => ({
          id: pslfForm.formId,
          type: FormTypes.pslf,
        })),
      )(pslfForms),
    [pslfForms],
  );

  const isLoading = formsReadyForSignature.length
    ? isSignRequestsLoading
    : false;

  const [handleButtonClick] = useHellosign(
    () =>
      formsReadyForSignature.length
        ? fetchSignatureRequestListV3(formsReadyForSignature)
        : {},
    {
      onOpen: useCallback(() => {
        trackServerSide('User opened PSLF Hellosign modal');
      }, [trackServerSide]),
      onSign: useCallback(
        data => {
          const currentForm = find(signatureRequestsList, signRequest => {
            const borrower = find(signRequest.signers, { role: 'borrower' });
            return borrower.signatureRequestsId === data.signatureId;
          });

          track('Pslf form signed', { currentPslfFormId: currentForm.formId });

          updateSignatureRequest(data.signatureId, true);
          togglePslfCompleteModal();
        },
        [
          signatureRequestsList,
          togglePslfCompleteModal,
          updateSignatureRequest,
        ],
      ),
    },
  );

  const lastUpdatedText = pslfForms => {
    const dateFormat = 'MMMM dd, yyyy';
    const lastUpdatedDate = _.flow(
      fp.maxBy('updatedAt'),
      fp.getOr('', 'updatedAt'),
    )(pslfForms);
    if (lastUpdatedDate) {
      return `Last updated ${format(parseISO(lastUpdatedDate), dateFormat)}`;
    }
    return '';
  };

  useOpenOnNavigate(isLoading, signatureRequestsList, handleButtonClick);

  const alertHellosignError = useAlertHellosignError(serverError);

  return (
    <Container>
      <PartTimeAlert />
      <SignatureRequestsContainer>
        <Top>
          <LastUpdated paragraph small color="secondary">
            {lastUpdatedText(pslfForms)}
          </LastUpdated>
          <Link to="/pslf/form" onClick={clearCurrentPslfForm}>
            <AddButton secondary small>
              + Add Another
            </AddButton>
          </Link>
        </Top>
        {alertHellosignError && <HellosignAlert />}
        {isLoading ? (
          <LoadingBars color={COLORS.azure} />
        ) : (
          map(
            pslfForms,
            ({
              employerHoursPerWeek,
              employerName,
              employerStartDate,
              employerEndDate,
              formId,
              currentStep,
              status,
            }) => {
              const dateFormat = 'MMM yyyy';
              const employerDateString =
                employerStartDate &&
                `${format(parseISO(employerStartDate), dateFormat)}${
                  employerEndDate
                    ? ` — ${format(parseISO(employerEndDate), dateFormat)}`
                    : ' — Present'
                }${employerHoursPerWeek < 30 ? ', Part Time' : ''}`;

              // get just the borrower sign requests
              const signatureRequest = _.flow(
                fp.map(signRequest => ({
                  ...signRequest,
                  signers: [find(signRequest.signers, { role: 'borrower' })],
                })),
                fp.find({
                  formId,
                }),
              )(signatureRequestsList);

              const editButtonHandler = () => {
                setCurrentPslfForm({ currentStep, formId });
                fetchCurrentPslfForm(formId);
              };

              const signedByBorrower = get(
                find(get(signatureRequest, 'signers'), ['role', 'borrower']),
                'complete',
                false,
              );

              const showBottomComponent =
                signedByBorrower &&
                status !== FormStatus.PROCESSED &&
                platformType !== MOBILE &&
                platformType !== TABLET;

              const bottomComponent = <CertificationAlert />;

              return (
                <SignRequestRow
                  key={formId}
                  editButtonHandler={editButtonHandler}
                  signRequestTitle={employerName}
                  signRequestSubtitle={employerDateString}
                  signRequest={signatureRequest || { formType: FormTypes.pslf }}
                  onClick={handleButtonClick}
                  showEditButton={
                    !(
                      status === FormStatus.SIGNED ||
                      status === FormStatus.PROCESSED
                    )
                  }
                  showBottomComponent={showBottomComponent}
                  bottomComponent={bottomComponent}
                  isLoading={alertHellosignError}
                  status={status}
                />
              );
            },
          )
        )}
      </SignatureRequestsContainer>

      <PslfCompleteModal
        isOpen={isPslfCompleteModalOpen}
        togglePopup={togglePslfCompleteModal}
      />
    </Container>
  );
};

const Container = styled.div`
  padding-left: 32px;
  padding-right: 32px;

  @media (max-width: ${MobileSize}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const SignatureRequestsContainer = styled.div`
  & > div {
    margin-bottom: 16px;
  }

  & > div:last-child {
    margin-bottom: 16px;
  }

  @media (max-width: ${MobileSize}) {
    margin: 0;
    width: 100%;
  }
`;

const Top = styled(FlexRow)`
  margin-bottom: 24px;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: ${MobileSize}) {
    align-items: center;
  }
`;

const LastUpdated = styled(Text)`
  @media (max-width: ${MobileSize}) {
    width: 144px;
    margin-right: 8px;
  }
`;

const AddButton = styled(Button)`
  width: 144px;
`;

const mapStateToProps = state => ({
  pslfForms: getUserPslfForms(state),
  signatureRequestsList: getPslfSignRequests(state),
  isSignRequestsLoading: isLoadingWithInit(
    state,
    'fetchSignatureRequestListV3',
  ),
  serverError: get(state, 'ui.serverErrors.fetchSignatureRequestListV3'),
});

export default connect(mapStateToProps, {
  fetchCurrentPslfForm,
  clearCurrentPslfForm,
  setCurrentPslfForm,
  fetchSignatureRequestListV3,
  updateSignatureRequest,
  trackServerSide,
})(Platform(CompletePage));
